import { useCallback } from "react";
import { useQuery } from "react-query";
import request from "../../util/request";
import _ from "lodash";

const QUERY_KEYS = {
  TAGS: "construction-tags",
  BUILDERS: "construction-builders",
};

const usePageQueries = ({ isAuthenticated, buildersTableVariables, currentTag }) => {
  const {
    data: tags,
  } = useQuery(
    QUERY_KEYS.TAGS,
    async () => {
      const response = await request({
        url: `new-ships-api/public/tags`,
        method: "GET",
      });

      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const fetchBuilders = useCallback(async () => {
    if (!currentTag?.id) {
      return;
    }
    let filters = _.omitBy(buildersTableVariables.filters, _.isNil);
    const params = {
      currentPage: buildersTableVariables.pagination.current,
      pageSize: buildersTableVariables.pagination.pageSize,
      orderBy: buildersTableVariables.sort.field,
      order: buildersTableVariables.sort.order || "ascend",
    };
    console.log("start quertt")
    const response = await request({
      url: `new-ships-api/public/tags/${currentTag?.id}/shipyards`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });

    console.log("response", response);
    return response;

  }, [buildersTableVariables, isAuthenticated, currentTag]);


  const {
    data: builders,
    isFetching: isBuildersFetching,
  } = useQuery([QUERY_KEYS.BUILDERS, buildersTableVariables, currentTag], fetchBuilders, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 300000,
    refetchOnWindowFocus: false,
  });

  return {
    tags,
    builders,
    isBuildersFetching
  };
};

export default usePageQueries;
