import axios from 'axios';
import { getHost } from './host';
import { getToken } from './token'
const client = axios.create({ baseUrl: getHost() });

const request = (options, useNewApi = false) => {
    const onSuccess = (response) => response.data
    return client({
      ...options,
      url: `${getHost(useNewApi)}/${options.url}`,
      headers: { ...options.headers, Authorization: `Bearer ${getToken()}` },
    }).then(onSuccess);
}

export default request;