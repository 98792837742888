import React from "react";

const RecyclingGuide = () => (
  <div className="gx-page-error-container">
    <div className="gx-page-error-content">
      <h2 className="gx-text-center">Coming Soon</h2>
    </div>
  </div>
);

export default RecyclingGuide;
