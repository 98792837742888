import _ from "lodash";

import Widget from "components/Widget";
import { Button, Tag } from "antd";


const CompanyCard = ({ shipyard, title, clickEvent }) => {
  return (
    <Widget styleName="gx-card-profile">
      <div className="ant-card-head gx-pb-md-0">

        <Tag
          className="gx-rounded-xs gx-text-uppercase"
          color="#06BB8A"
          style={{ display: "table" }}
        >
          {title}
        </Tag>



      </div>
      <div className="gx-pb-md-3">
        <div className="">
          <div className="gx-media-body gx-featured-content">
            <div className="gx-featured-content-left">
              <a
                href={`/app/shipyards/${shipyard.id}-${_.kebabCase(shipyard.name)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h3 className="gx-text-primary gx-mt-sm-auto  gx-pointer">{shipyard.name}</h3>
              </a>
              {
                shipyard?.company?.web &&
                <div className="gx-text-grey gx-fs-sm">
                  <span>{shipyard?.company?.web}</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="gx-pb-md-3">
        <div className="gx-featured-content-left">
          <div>
            {clickEvent &&
              <Button type="primary" onClick={clickEvent}>
                Claim
              </Button>
            }
          </div>
        </div>
      </div>
    </Widget>
  )
};

export default CompanyCard;