import React, { useMemo, useEffect } from "react";
import { Card, Col, Row, Table } from "antd";
import moment from "moment";
import _ from "lodash";
import AdvancedSearchForm from "../../components/AdvancedSearchForm";
import CircularProgress from "../../components/CircularProgress";
import { useIntl } from "react-intl";
import useSearchQueries from "./queries";
import { useAuth0 } from "@auth0/auth0-react";
import ContentAuthorization from "../ContentAuthorization";
import UserSearches from "../../components/UserSearches";

const Search = () => {
  const intl = useIntl();
  const [tableVariables, setTableVariables] = React.useState({
    pagination: { current: 1, pageSize: 20 },
    filters: {},
    sort: {
      column: { title: "Order Date", dataIndex: "orderDate", key: "orderDate" },
      order: "descend",
      field: "orderDate",
      columnKey: "orderDate",
    },
  });

  const { isAuthenticated } = useAuth0();

  const { permissions, vesselTypesQuery, continentsAndCountriesQuery, data, isError, refetch, fetchSearchIsLoading, saveUserSearch, userSearches, deleteByCompanyMailingId } =
    useSearchQueries({ tableVariables, isAuthenticated });

  const pagePermissions = permissions || {};

  useEffect(() => {
    refetch();
  }, [tableVariables]); // eslint-disable-line

  const orderbookColumns = useMemo(
    (isAuthenticated) => [
      {
        title: "Imo",
        dataIndex: "imo",
        render: (text, record) => (
          <a
            href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {text}{" "}
          </a>
        ),
      },
      {
        title: "Shipyard",
        dataIndex: "shipyard",
        key: "shipyard",
        render: (text, record) => (
          <>
            {record.shipyard?.url && (
              <span className="gx-link">
                <a
                  href={`/app${record.shipyard?.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {record.shipyard?.name}
                </a>
              </span>
            )}

            {!record.shipyard?.url && <>{record.shipyard?.name}</>}
          </>
        ),
        sorter: (a, b) => { },
      },
      {
        title: "Hull No.",
        dataIndex: "hullNo",
      },
      {
        title: "Orderer",
        dataIndex: "orderer",
        key: "orderer",
        render: (text, record) => (
          <>
            {record.orderer?.ordererUrl && (
              <span className="gx-link">
                <a
                  href={`/app/orderers/${record.orderer.id}-${_.kebabCase(record.orderer.name)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {record.orderer?.name}
                </a>
              </span>
            )}

            {!record.orderer?.ordererUrl && <>{record.orderer?.name}</>}
          </>
        ),
        sorter: (a, b) => { },
      },
      {
        title: "Vessel Type",
        dataIndex: "tdVesselType",
        render: (text, record) => (
          <a
            href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {text}{" "}
          </a>
        ),
        sorter: () => { },
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => (
          <a
            href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            {text}{" "}
          </a>
        ),
      },
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        sorter: () => { },
        defaultSortOrder: "descend",
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        sorter: () => { },
      },
      {
        title: "DWT",
        dataIndex: "deadweight",
        key: "deadweight",
        sorter: () => { },
        defaultSortOrder: "descend",
      },
      {
        title: "TEU",
        dataIndex: "teu",
        key: "teu",
        sorter: () => { },
      },
      {
        title: "Status",
        dataIndex: "tdStatus",
        key: "tdStatus",
        render: (text, record) => (
          <>
            {_.startCase(_.lowerCase(text))}
          </>
        ),
        sorter: () => { },
      },
      {
        title: "Order Date",
        dataIndex: "orderDate",
        key: "orderDate",
        render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
        sorter: () => { },
      },
      {
        title: "Delivery Date",
        dataIndex: "deliveryDate",
        key: "deliveryDate",
        render: (d) => (d ? moment(d).format("DD.MMM.YYYY") : ""),
        sorter: () => { },
      },
    ],
    [intl]
  );

  const handleTableChange = (pagination, filters, sort, extra) => {
    setTableVariables({
      ...tableVariables,
      sort: {
        ...tableVariables.sort,
        ...sort,
      },
      pagination: {
        ...tableVariables.pagination,
        ...pagination,
      },
    });
  };

  //change variables; useEffect in this component calls refetch()
  const handleAdvancedSearchSubmit = (filters) => {
    setTableVariables({
      ...tableVariables,
      filters: {
        gt: filters.gt,
        dwt: filters.dwt,
        teu: filters.teu,
        shipyardContinent: filters["shipyard-continent"],
        shipyardCountry: filters["shipyard-country"],
        ordererContinent: filters["orderer-continent"],
        ordererCountry: filters["orderer-country"],
        hullNo: filters["hull-no"],
        machineryDesign: filters["machinery-design"],
        machineryType: filters["machinery-type"],
        machineryPower: filters["machinery-power"],
        serviceSpeed: filters["service-speed"],
        strokeEngine: filters["stroke-engine"],
        iceClass: filters["ice-class"],
        classificationSociety: filters["class"],
        shipNote: filters["ship-note"],
        imoNo: filters["imo-no"],
        shipTypeSize: filters["ship-type-size"],
        bollardPull: filters["bollard-pull"],
        reeferplugs: filters["reeferplugs"],
        capacityBale: filters["capacity-bale"],
        capacityGrain: filters["capacity-grain"],
        capacityLiquid: filters["capacity-liquid"],
        cargohandling: filters["cargohandling"],
        cargoPassenger: filters["passenger"],
        typeOfDrive: filters["type-of-drive-power"],
        cars: filters["cars"],
        trailer: filters["trailer"],
        shipId: filters["ship-id"],
        laneMeter: filters["lane-meter"],
        orderDateStart: filters["order-date"][0]?.unix(),
        orderDateEnd: filters["order-date"][1]?.unix(),
        deliveryDateStart: filters["delivery-date"][0]?.unix(),
        deliveryDateEnd: filters["delivery-date"][1]?.unix(),
        orderStatus: filters["order-status"].map((i) => i), // if 1 option is selected order-status is string otherwise its string[]
        vesselType: filters["vessel-type"],
        length: filters["length"]?.every((i) => i === 0)
          ? undefined
          : filters["length"],
        width: filters["width"]?.every((i) => i === 0)
          ? undefined
          : filters["width"],
        breadth: filters["breadth"]?.every((i) => i === 0)
          ? undefined
          : filters["breadth"],
        draught: filters["draught"]?.every((i) => i === 0)
          ? undefined
          : filters["draught"],
        shipName: filters["ship-name"],
        shipyard: filters["shipyardObject"]?.value,
        orderer: filters["ordererObject"]?.value,
        shipyardName: filters["shipyardObject"]?.name,
        ordererName: filters["ordererObject"]?.name,
        shipyardId: filters["shipyardObject"]?.value?.split("/")[2]?.split("-")[0],
        ordererId: filters["ordererObject"]?.value?.split("/")[2]?.split("-")[0],
        shipyardLocationExclude: filters["shipyard-location-exclude"] ? filters["shipyard-location-exclude"] : false,
        ordererLocationExclude: filters["orderer-location-exclude"] ? filters["orderer-location-exclude"] : false,
      },
      pagination: {
        ...tableVariables.pagination,
        current: 1,
      },
    });
  };

  const tableData = isError ? [] : data || [];
  const vesselTypes = vesselTypesQuery.data || [];
  const continentsAndCountries = continentsAndCountriesQuery.data || {};

  if (fetchSearchIsLoading || vesselTypesQuery.isLoading)
    return <CircularProgress />;

  return (
    <>
      <Row>
        <Col span={24}>
          <AdvancedSearchForm
            vesselTypes={vesselTypes}
            filters={tableVariables.filters}
            handleSubmit={handleAdvancedSearchSubmit}
            continentsAndCountries={continentsAndCountries}
            setTableVariables={setTableVariables}
          />
        </Col>
      </Row>
      {isError && (
        <Row>
          <Col span={16} offset={4}>
            <Card className="gx-card">
              Sorry, an error occurred. Please try again later.
            </Card>
          </Col>
        </Row>
      )}

      {!isError &&
        <ContentAuthorization
          sectionId="orderer-company_contacts"
          permission={pagePermissions.nso_order_details}
        >
          <Row>
            <Col span={24}>
              <UserSearches tableVariables={tableVariables} saveUserSearch={saveUserSearch} userSearches={userSearches} deleteByCompanyMailingId={deleteByCompanyMailingId} setTableVariables={setTableVariables} />
            </Col>
          </Row>
        </ContentAuthorization>
      }

      {!isError && (
        <>
          {tableData.total > 0 &&
            <h2>{tableData.total} records found</h2>
          }
          {!tableData.total &&
            <h2>No records found</h2>
          }
          <ContentAuthorization
            sectionId="shipyard-company_main_details"
            permission={pagePermissions.nso_order_details}
          >
            <Table
              className="gx-table-responsive"
              columns={orderbookColumns}
              rowKey="id"
              dataSource={isAuthenticated ? tableData.data : tableData.data.splice(0, 5)}
              sortDirections={["ascend", "descend", "ascend"]}
              onChange={handleTableChange}
              pagination={{
                position: ["topRight", "bottomRight"],
                total: tableData.total,
                defaultPageSize: tableVariables.pagination.pageSize,
                showSizeChanger: false,
              }}
            />
          </ContentAuthorization>
        </>
      )}
    </>
  );
};

export default Search;