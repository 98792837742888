import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Checkbox, Col, Divider, Form, Row, Select, Steps } from "antd";
import FormItem from "antd/lib/form/FormItem";
import Input from "antd/lib/input/Input";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { postUserCompanyData } from "../../appRedux/actions/Auth";

import request from "../../util/request";
import { allCountries } from "../../util/country";
import CompanyCard from "../../components/CompanyCard";
import _ from "lodash";
import NoCompanyFoundCard from "../../components/NoCompanyFoundCard";

const steps = [{
  title: 'Please claim your company',
  key: "company"
}, {
  title: 'Create a company',
  key: "create_company"
}, {
  title: 'Please fillout your personal info',
  key: "person"
}, {
  title: 'Send request',
  key: "request"
}];

const EmailConfirmation = () => {
  const userCompanyUpdated = useSelector(({ auth }) => auth.userCompanyUpdated);

  const [claimCompany, setClaimCompany] = useState(null);

  useEffect(() => {
    if (userCompanyUpdated) {
      window.location.replace("/app/register-success");
    }
  }, [userCompanyUpdated]);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [showInsertCompany, setShowInsertCompany] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");


  const [checkCompany, setCheckCompany] = useState(true);

  const [data, setData] = useState({});

  const { isAuthenticated } = useAuth0();
  const [current, setCurrent] = useState(0)

  const next = () => {
    setCurrent(current + 1)
  }

  const previous = () => {
    setCurrent(current - 1)
  }

  //ensure user creation
  const { refetch } = useQuery(
    "USER_DETAILS",
    async () => {
      const response = await request({
        url: "new-ships-api/user/user-info",
        method: "GET",
      });
      return response;
    },
    { enabled: false }
  );

  //ensure user creation
  useEffect(() => {
    if (!isAuthenticated) return null;
    refetch();
  }, [isAuthenticated, refetch]);


  const { refetch: refetchSearch, data: companies } = useQuery(
    "SEARCH_COMPANY",
    async () => {
      if (companyName?.length < 3) return [];
      let types = ["COMPANY", "PORT_TERMINAL_BERTH", "SHIPYARD", "SHIPOWNER", "SHIPMANAGER", "EQUIPMENT_MANUFACTURER", "SERVICE_SUPPLIER"];
      if (companyType) {
        types = [companyType];
      }
      const encodedDomain = encodeURIComponent(companyDomain);
      const response = await request({
        url: `new-ships-api/public/search?web=${encodedDomain}&itemTypes=${types.join("&itemTypes=")}&term=${companyName}`,
        method: "GET",
      });
      return response;
    },
    { enabled: false }
  );

  useEffect(() => {
    refetchSearch();
  }, [companyName, companyType, companyDomain, refetchSearch]);

  return (
    <Card className="gx-card" title="Provide Your Details for Better Support">
      <p>Please fill out your company and contact information so we can better support you with our product.</p>
      <Steps current={current}>
        {steps.map(item => <Steps.Step key={item.title} title={item.title} />)}
      </Steps>
      <div className="steps-content" style={{ minHeight: "200px", padding: "40px" }}>
        <Form
          form={form}
          initialValues={{ remember: false, companyId: null, allowContact: true }}
          name="basic"
          onValuesChange={() => {
            setCompanyName(form.getFieldValue("companyName"));
            setCompanyType(form.getFieldValue("companyType"));
            setCompanyDomain(form.getFieldValue("website"));
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0">
          <FormItem name="companyId" style={{ display: 'none' }}>
            <Input type="hidden" />
          </FormItem>

          {steps[current].key === 'company' &&
            <>

              <Row>
                <Col md={8} >
                  <FormItem rules={[{ required: checkCompany, message: 'Please input your company type.' }]} name="companyType">
                    <Select
                      placeholder="Company type"
                    >
                      <Select.Option value="COMPANY">Others</Select.Option>
                      <Select.Option value="SHIPYARD">Shipyard</Select.Option>
                      <Select.Option value="SHIPOWNER">Shipowner</Select.Option>
                      <Select.Option value="SHIPMANAGER">Shipmanager</Select.Option>
                      <Select.Option value="EQUIPMENT_MANUFACTURER">Equipment Manufacturer</Select.Option>
                      <Select.Option value="SERVICE_SUPPLIER">Service Supplier</Select.Option>
                      <Select.Option value="PORT_TERMINAL_BERTH">Port / Terminal / Berth</Select.Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col md={8} className="full-width-search">
                  <FormItem rules={[
                    {
                      validator: async (_, value) => {
                        const trimmedValue = value.trim();
                        if (trimmedValue.length <= 2) {
                          return Promise.reject('company name must be greater than 2 characters!');
                        }
                        return Promise.resolve();
                      }, required: checkCompany, message: 'Please input your company name.'
                    }]} name="companyName">
                    <Input
                      placeholder="Company Name" />
                  </FormItem>
                </Col>
                <Col md={8} >
                  <FormItem rules={[
                    {
                      validator: async (_, value) => {
                        const trimmedValue = value.trim();
                        if (trimmedValue.length <= 2) {
                          return Promise.reject('company website must be greater than 2 characters!');
                        }
                        const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
                        if (!domainRegex.test(trimmedValue)) {
                          return Promise.reject(new Error('The input is not a valid domain!'));
                        }
                        return Promise.resolve();
                      }, required: checkCompany, message: 'Please input your company website.'
                    }]} name="website">
                    <Input
                      placeholder="Company website" />
                  </FormItem>
                </Col>
              </Row>


              {companies && companies.length >= 0 &&
                <Row gutter={[16, 16]}>
                  {(companies?.length > 0 || companyName?.length > 3) &&
                    <Col md={8} style={{ display: "flex", flexDirection: "column" }}>
                      <NoCompanyFoundCard shipyard={{ name: "Cannot find any company matching yours? Please click the following button to create a new one." }} title="Not Found?" clickEvent={() => { setShowInsertCompany(true); setCurrent(1); }} />
                    </Col>
                  }
                  {companies?.slice(0, 5).map((c, idx) =>
                    <Col md={8} key={`companyxx_${idx}`} style={{ display: "flex", flexDirection: "column" }}>
                      <CompanyCard shipyard={c} title={_.startCase(_.replace(c?.type, /_/g, ' '))} clickEvent={() => {
                        form.setFieldsValue({ companyId: c.id });
                        setCheckCompany(false);
                        setShowInsertCompany(false);
                        setClaimCompany(c);
                        setData({
                          ...data,
                          ...form.getFieldsValue(),
                          companyId: c.id
                        })

                        setCurrent(2);
                      }} />
                    </Col>
                  )}
                </Row>
              }



            </>
          }

          {steps[current].key === 'create_company' &&
            <>
              {!showInsertCompany &&
                <Row>
                  <Col md={24} >
                    <h1>You have claim following company: </h1>
                  </Col>
                  <Col md={8} >
                    <CompanyCard shipyard={claimCompany} title={_.startCase(_.replace(claimCompany?.type, /_/g, ' '))} />
                  </Col  >
                </Row>
              }
              {showInsertCompany &&
                <>
                  <Row>
                    <Col md={8} >
                      <FormItem rules={[
                        {
                          validator: async (_, value) => {
                            const trimmedValue = value.trim();
                            if (trimmedValue.length <= 2) {
                              return Promise.reject('Company city must be greater than 2 characters!');
                            }
                            return Promise.resolve();
                          },
                          required: checkCompany, message: 'Please input your company type.'
                        }]} name="companyType">
                        <Select
                          placeholder="Company type"
                        >
                          ‚<Select.Option value="OTHERS">Others</Select.Option>
                          <Select.Option value="SHIPYARD">Shipyard</Select.Option>
                          <Select.Option value="SHIPOWNER">Shipowner</Select.Option>
                          <Select.Option value="SHIPMANAGER">Shipmanager</Select.Option>
                          <Select.Option value="EQUIPMENT_MANUFACTURER">Equipment Manufacturer</Select.Option>
                          <Select.Option value="SERVICE_SUPPLIER">Service Supplier</Select.Option>
                          <Select.Option value="PORT_TERMINAL_BERTH">Port / Terminal / Berth</Select.Option>
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} >
                      <FormItem rules={[
                        {
                          validator: async (_, value) => {
                            const trimmedValue = value.trim();
                            if (trimmedValue.length <= 2) {
                              return Promise.reject('Company name must be greater than 2 characters!');
                            }
                            return Promise.resolve();
                          }, required: checkCompany, message: 'Please input your company name.'
                        }]} name="companyName">
                        <Input
                          placeholder="Company name" />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} >
                      <FormItem rules={[
                        {
                          validator: async (_, value) => {
                            const trimmedValue = value.trim();
                            if (trimmedValue.length <= 2) {
                              return Promise.reject('company website must be greater than 2 characters!');
                            }
                            const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
                            if (!domainRegex.test(trimmedValue)) {
                              return Promise.reject(new Error('The input is not a valid domain!'));
                            }
                            return Promise.resolve();
                          }, required: checkCompany, message: 'Please input your company website.'
                        }]} name="website">
                        <Input
                          placeholder="Company website" />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} >
                      <FormItem rules={[{
                        validator: async (_, value) => {
                          const trimmedValue = value.trim();
                          if (trimmedValue.length <= 2) {
                            return Promise.reject('Company address must be greater than 2 characters!');
                          }
                          return Promise.resolve();
                        }, required: checkCompany, message: 'Please input your company address.'
                      }]} name="companyAddress">
                        <Input
                          placeholder="Company Address" />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} >
                      <FormItem rules={[{
                        validator: async (_, value) => {
                          const trimmedValue = value.trim();
                          if (trimmedValue.length <= 2) {
                            return Promise.reject('Company zip must be greater than 2 characters!');
                          }
                          return Promise.resolve();
                        }, required: checkCompany, message: 'Please input your company zip.'
                      }]} name="companyZip">
                        <Input
                          placeholder="Company zip" />
                      </FormItem>
                    </Col>
                    <Col md={4} >
                      <FormItem rules={[{
                        validator: async (_, value) => {
                          const trimmedValue = value.trim();
                          if (trimmedValue.length <= 2) {
                            return Promise.reject('Company city must be greater than 2 characters!');
                          }
                          return Promise.resolve();
                        }, required: checkCompany, message: 'Please input your company city.'
                      }]} name="companyCity">
                        <Input
                          placeholder="Company city" />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} >
                      <FormItem rules={[{
                        validator: async (_, value) => {
                          if (!value) {
                            return Promise.reject('Company city must be greater than 2 characters!');
                          }
                          return Promise.resolve();
                        },
                        required: checkCompany, message: 'Please select your company country.'
                      }]} name="companyCountryId">
                        <Select
                          placeholder="Company country"
                        >
                          <Select.Option value="">please choose country</Select.Option>
                          {allCountries.sort((a, b) => a.name.localeCompare(b.name)).map((c, idx) => {
                            return (
                              <Select.Option value={c.id} key={`country_${idx}`}>{c.name}</Select.Option>
                            )
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                </>
              }
            </>
          }
          {steps[current].key === 'person' &&
            <>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{
                    required: false, message: 'Please input your title.'
                  }]} name="title">
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Title" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{
                    required: false, message: 'Please input your copmany position.'
                  }]} name="position">
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Position" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{
                    validator: async (_, value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue.length <= 2) {
                        return Promise.reject('Lastname must be greater than 2 characters!');
                      }
                      return Promise.resolve();
                    }, required: true, message: 'Please input your lastname.'
                  }]} name="lastName">
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Lastname" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{
                    validator: async (_, value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue.length <= 2) {
                        return Promise.reject('FirstName must be greater than 2 characters!');
                      }
                      return Promise.resolve();
                    }, required: true, message: 'Please input your firstName.'
                  }]} name="firstName">
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Firstname" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{
                    validator: async (_, value) => {
                      const cannotContain = ['qq.com', 'yahoo', 'gmail', 'mail.ru']; //
                      const trimmedValue = value.trim();
                      if (trimmedValue.length <= 2) {
                        return Promise.reject('working E-mail must be greater than 2 characters!');
                      }
                      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                      if (!emailRegex.test(trimmedValue)) {
                        return Promise.reject(new Error('The input is not a valid email!'));
                      }
                      for (const keyword of cannotContain) {
                        if (trimmedValue.includes(keyword)) {
                          return Promise.reject(new Error(`Email cannot contain "${keyword}"!`));
                        }
                      }
                      return Promise.resolve();
                    }, required: true, message: 'Please input your working E-mail.'
                  }]} name="email">
                    <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Working email" />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col md={8} >
                  <FormItem rules={[{
                    validator: async (_, value) => {
                      const trimmedValue = value.trim();
                      if (trimmedValue.length <= 2) {
                        return Promise.reject('Phone number must be greater than 2 characters!');
                      }
                      return Promise.resolve();
                    }, required: true, message: 'Please input your phone number.'
                  }]} name="phone">
                    <Input prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Phone" />
                  </FormItem>
                </Col>
              </Row>
            </>
          }
          {steps[current].key === 'request' &&
            <>
              <Row>
                {showInsertCompany &&
                  <Col md={8}>
                    <h3>Comany Information</h3>
                    <p><strong>Type:</strong> {form.getFieldValue('companyType')}</p>
                    <p><strong>Name:</strong> {form.getFieldValue('companyName')}</p>
                    <p><strong>Web:</strong> {form.getFieldValue('website')}</p>
                    <p><strong>Address:</strong> {form.getFieldValue('companyAddress')}</p>
                    <p><strong>Zip:</strong> {form.getFieldValue('companyZip')}</p>
                    <p><strong>City:</strong> {form.getFieldValue('companyCity')}</p>
                    <p><strong>Country:</strong> {allCountries
                      .filter(country => country.id === form.getFieldValue('companyCountryId'))
                      .map(country => country.name)}</p>
                  </Col>
                }
                {!showInsertCompany &&
                  <Col md={8}>
                    <h3>Comany Information</h3>
                    <CompanyCard shipyard={claimCompany} title={_.startCase(_.replace(claimCompany?.type, /_/g, ' '))} />
                  </Col>
                }
              </Row>
              <Divider />
              <Row>
                <Col md={8}>
                  <h3>User Information</h3>
                  <p><strong>Title:</strong> {form.getFieldValue('title')}</p>
                  <p><strong>Position:</strong> {form.getFieldValue('position')}</p>
                  <p><strong>Firstname:</strong> {form.getFieldValue('firstName')}</p>
                  <p><strong>Lastname:</strong> {form.getFieldValue('lastName')}</p>
                  <p><strong>Working Email:</strong> {form.getFieldValue('email')}</p>
                  <p><strong>Phone Number:</strong> {form.getFieldValue('phone')}</p>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col md={24} >
                  <p>
                    A free user account will be created for both new-ships.com and trusteddocks.com. You can later upgrade this to a premium / paid subscription.
                  </p>
                </Col>
              </Row>
            </>
          }
        </Form>
      </div>
      <div className="steps-action">
        {current > 0 &&
          <Button type="primary" onClick={async () => {
            previous()
          }}>Previous</Button>
        }
        {current < steps.length - 1 && current > 0 &&
          <Button type="primary" onClick={async () => {
            let checked = false;
            switch (steps[current].key) {
              case "company":
                try {
                  await form.validateFields();
                  checked = true
                  setData({
                    ...data,
                    ...form.getFieldsValue()
                  })
                } catch (errors) {
                }
                break;
              case "create_company":
                try {
                  await form.validateFields();
                  checked = true
                  setData({
                    ...data,
                    ...form.getFieldsValue()
                  })
                } catch (errors) {
                }
                break;
              case "person":
                try {
                  await form.validateFields();
                  checked = true
                  setData({
                    ...data,
                    ...form.getFieldsValue()
                  })
                } catch (errors) {
                }
                break;
              default:
                break;
            }
            if (checked) {
              next()
            }
          }}>Next</Button>
        }

        {current === steps.length - 1 &&
          <Button type="primary" htmlType="submit" onClick={async () => {
            try {
              await form.validateFields();
              dispatch(postUserCompanyData({ ...data, company: data.companyName }))
            } catch (errors) {
            }
          }}>
            Finish registration
          </Button>
        }
      </div>
    </Card>
  );
};

export default EmailConfirmation;
