import React from 'react'
import Widget from '../../components/Widget'
import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

const PaymentSuccess = () => {
    const [countdown, setCountdown] = useState(7);
    let history = useHistory();


    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        if (countdown === 0) {
            history.push("/");
        }

        return () => clearInterval(interval);
    }, [countdown]);

    return (
        <Widget>
            <Alert message={`The payment has been successful! Redirecting in ${countdown}...`} type="success" />
        </Widget>
    )
}

export default PaymentSuccess