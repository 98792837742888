import React, { useEffect, useState } from "react";
import { Alert, Button, Layout, Popover, Row, Space } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "../../../components/UserInfo";
import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import { toggleCollapsedSideNav } from "../../../appRedux/actions";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
import Widget from "../../../components/Widget/index";


import { useAuth0 } from "@auth0/auth0-react";
import SearchWithAutoComplete from "../../../components/SearchWithAutoComplete";
import usePageQueries from "./queries";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Wrapper from "../../App/Wrapper";

const { Header } = Layout;

const HorizontalDefault = () => {
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const dispatch = useDispatch();


  const {
    user, isAuthenticated, logout
  } = useAuth0();

  useEffect(() => {
  }, [user, isAuthenticated])

  const {
    userInfo
  } = usePageQueries({ isAuthenticated });

  const [showUpgradeAlert, setShowUpgradeAlert] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname !== "/email-confirmation" && location?.pathname !== "/register-success" && location?.pathname !== "/register-rejected") {
      setShowUpgradeAlert(true);
    }
  }, [location.pathname])

  return (
    <div className="gx-header-horizontal">
      <Header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
            <Link
              to="/"
              className="gx-d-block gx-d-lg-none gx-pointer gx-w-logo"
            >
              <img style={{ height: "20px" }} alt="" src="/app/assets/images/w-logo.png" />
            </Link>
            <Link
              to="/"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
            >
              <img
                alt=""
                src="/app/assets/images/logo_w_text.png"
                style={{ height: "60px" }}
              />
            </Link>
            <div className="gx-header-search gx-d-none gx-ml-auto gx-d-lg-flex">
              <SearchWithAutoComplete standAlone types={['SHIPYARD', 'VESSEL', 'SHIPMANAGER', 'COMPANY_GROUP']} placeholder="Search Shipyard, Orderer , Order" />
            </div>

            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  content={
                    <div className="gx-d-flex">
                      <SearchWithAutoComplete standAlone types={['SHIPYARD', 'VESSEL', 'SHIPMANAGER', 'COMPANY_GROUP']} placeholder="Search Shipyard, Orderer , Order" />
                    </div>
                  }
                  trigger="click"
                >
                  <span className="gx-pointer gx-d-block">
                    <i className="icon icon-search-new" />
                  </span>
                </Popover>
              </li>
              <li id="gx-language">

              </li>
              {isAuthenticated && (
                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              )}
              {!isAuthenticated && (
                <ul className="gx-login-list" style={{ display: "block" }}>
                  <Button type="primary" style={{ marginRight: '10px' }} onClick={() => {
                    window.location.replace("/app/auth");
                  }}>Login</Button>

                  <Button type="primary" onClick={() => {
                    logout({
                      returnTo: `${window.location.origin}/app?signup=true`,
                    });
                  }}>Register</Button>
                </ul>
              )}
            </ul>
          </div>
        </div>
      </Header>

      {width >= TAB_SIZE && (
        <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve">
          <div className="gx-container">
            <div className="gx-header-horizontal-nav">
              <HorizontalNav />
            </div>
          </div>
        </div>
      )}

      {isAuthenticated && userInfo && !userInfo?.addonsNames?.includes('NEW_SHIP_ORDER') && showUpgradeAlert &&
        <Widget styleName="mb0">
          <Alert
            message="Free Account Notice"
            description="You're currently using a free account on new-ship.com. Some content and features are limited. Upgrade now to unlock full access and enjoy all our premium features!"
            type="warning"
            showIcon
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            action={
              <Space direction="horizontal" style={{ display: 'flex', gap: '15px', }}>
                <a href="https://new-ships-discover.trusteddocks.com/pricing/" target="_blank" rel="noopener noreferrer">
                  <Button size="large" type="primary" style={{marginBottom: 0}} block>
                    Upgrade Now
                  </Button>
                </a>
                <a href="https://new-ships-discover.trusteddocks.com/contact-us/" target="_blank" rel="noopener noreferrer">
                  <Button size="large" type="primary" style={{marginBottom: 0}} block>
                    Contact Us
                  </Button>
                </a>
              </Space>
            }
          />
        </Widget>
      }
    </div>
  );
};

export default HorizontalDefault;
