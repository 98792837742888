import { useAuth0 } from "@auth0/auth0-react";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSingleNewsQuery } from "../../containers/News/queries";
import CircularProgress from "../CircularProgress";
import Widget from "../Widget";
import { Col, Row, Tag } from "antd";
import moment from "moment";
import InnerHTML from "dangerously-set-html-content";
import RelatedItemsCard from "../Widgets/RelatedItemsCard";
import Error404 from "../../routes/customViews/errorPages/404";
import { useMediaQuery } from 'react-responsive';

const NewsDetails = () => {
  const { isAuthenticated } = useAuth0();
  const isXs = useMediaQuery({ maxWidth: 575 });

  let { slug } = useParams();
  const slugId = slug.split("-");
  const slugNumber = parseInt(slugId[0]);

  const {
    data: specificNews,
    isLoading: specificNewsLoading,
    isError: specificNewsError,
  } = useSingleNewsQuery(slugNumber);

  const [filteredNews, setFilteredNews] = useState(null);

  useEffect(() => {
    specificNews && setFilteredNews(specificNews);
  }, [specificNews]);

  if (specificNewsLoading) return <CircularProgress />;
  if (specificNewsError) return <div>Sorry an Error occurred</div>;


  return (
    <div>
      <Row>
        <Col xl={18} lg={17} md={17} sm={15} xs={24} className="responsive-col">
          <Widget>
            <div className="gx-media gx-align-items-center">
              <div className="gx-media-body">
                <h2 className="gx-mb-1">{filteredNews?.title}</h2>

                <p className="gx-text-grey"></p>
                <p className="gx-mb-4">
                  {filteredNews?.content && (
                    <InnerHTML html={filteredNews?.content} className="gx-mb-4" />
                  )}
                </p>
              </div>
            </div>
            <div
              style={{ borderBottom: "1px solid #ccc", margin: "10px 0" }}
            ></div>
            <p className="gx-text-grey">
              {filteredNews?.tagsArray?.map((t, idx) => {
                return <Tag key={`tag_${idx}`}>{t}</Tag>;
              })}
            </p>

            <p className="gx-text-grey">
              {`Written ${moment(filteredNews?.creationDate).fromNow()}`} by {filteredNews?.user?.name}.
            </p>
          </Widget>
        </Col>

        <Col xl={6} lg={6} md={7} sm={8} xs={24}>
          <RelatedItemsCard
            items={filteredNews?.relatedItems}
          ></RelatedItemsCard>
        </Col>
      </Row>
    </div>
  );
};

export default NewsDetails;
