export const getHost = (useNewApi = false) => {

  let host = process.env.REACT_APP_API_ADDRESS || 'https://testlive.trusteddocks.com'

  if (process.env.NODE_ENV === 'production') {
    host = 'https://www.trusteddocks.com'
  }

  if (useNewApi) {
    host = process.env.NODE_ENV === 'production' ? 'https://api.new-ships.com' : 'http://localhost:8000'
  }

  return host
}