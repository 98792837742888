import React, { memo, useEffect, useMemo, useRef, useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import { Route, Switch, useHistory, useLocation, Redirect } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import { useAuth0 } from "@auth0/auth0-react";
import AppLocale from "lngProvider";

import MainApp from "./MainApp";
import SignIn from "../SignIn";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import { fetchTranslation } from "../../appRedux/actions/translation.action";
import { fetchVesselTypes } from "../../appRedux/actions/Vessel.action";
import CircularProgress from "../../components/CircularProgress";
// import AuthError from "../AuthError";
import SignUp from "../Signup";
// import Shipyard from "../Shipyard";
// import CompanyGroup from "../CompanyGroup";
import Wrapper from "./Wrapper";
import request from "../../util/request";
import { useQuery } from "react-query";
import Search from "../Search";
import NewsDetails from "../../components/NewsDetails";
import ConstructionGuide from "../ConstructionGuide";
import RecyclingGuide from "../RecyclingGuide";
import PaymentSuccess from "../PaymentSuccess";

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

let styleSheetLink = document.createElement("link");
styleSheetLink.type = "text/css";
styleSheetLink.rel = "stylesheet";
document.body.appendChild(styleSheetLink);

// const Home = lazy(() => import('../../routes/Home'));
// const News = lazy(() => import("../News"));

const Home = lazy(() => import('../../routes/Home'));
const News = lazy(() => import("../News"));
const Order = lazy(() => import("../Order"));
const Vessel = lazy(() => import("../Vessel"));
const Orderer = lazy(() => import("../Orderer"));
const RegisterSuccess = lazy(() => import("../RegisterSuccess"));
const LoginOrRegister = lazy(() => import("../LoginOrRegister"));
const AuthError = lazy(() => import("../AuthError"));
const Shipyard = lazy(() => import("../Shipyard"));
const CompanyGroup = lazy(() => import("../CompanyGroup"));


const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeColor = useSelector(({ settings }) => settings.themeColor);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
  const translation = useSelector(({ translation }) => translation);
  const redirectLocation = useRef(null);

  const { isLoading, getAccessTokenSilently, isAuthenticated, user } = useAuth0();

  let [accessToken, setAccessToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) return false;
    getAccessTokenSilently().then((token) => {
      localStorage.setItem("apiToken", token);
      const userId = user.sub;
      setAccessToken(token);
      window.gtag('config', 'G-KQZHWEKFVM', {
        user_id: userId,
      });
    });
  }, [isAuthenticated, getAccessTokenSilently]);

  const loadingAuthentication = useMemo(() => {
    if (!isAuthenticated) {
      setUserInfo(null);
    }
    if (isLoading) return true;
    if (!isAuthenticated) return false;
    if (!accessToken) return true;
    return false;
  }, [isLoading, isAuthenticated, accessToken]);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`;
    }
  }, [themeColor, isDirectionRTL]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
      styleSheetLink.href = "/css/dark_theme.min.css";
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
      styleSheetLink.href = "";
    }
  }, [themeType]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
  }, [location.search, dispatch, location.pathname]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  useEffect(() => {
    dispatch(fetchVesselTypes());
  }, [dispatch]);

  //ensure user creation
  const { refetch } = useQuery(
    "USER_DETAILS",
    async () => {
      const response = await request({
        url: "new-ships-api/user/user-info",
        method: "GET",
      });

      if (response && !response.company) {
        window.location.replace("/app/email-confirmation");
      }

      return response;
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setUserInfo(data);
      },
    }
  );

  useEffect(() => {
    if (
      userInfo &&
      !userInfo.company &&
      location.pathname !== "/email-confirmation"
    ) {
      window.location.replace("/app/email-confirmation");
    } else if (userInfo && !userInfo.approved && location.pathname !== "/news" && location.pathname !== "/email-confirmation" && location.pathname !== "/register-rejected") {
      window.location.replace("/app/register-rejected");
    }
  }, [location.pathname, userInfo]);

  //ensure user creation
  useEffect(() => {
    if (!isAuthenticated || !accessToken) return null;
    refetch();
  }, [isAuthenticated, accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentAppLocale = AppLocale[locale.locale];

  const AuthenticatedRoutes = (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={translation[currentAppLocale.locale]}
        onError={(err) => {
          if (err.code === "MISSING_TRANSLATION") {
            return;
          }
          throw err;
        }}
      >
        <Switch>
          <Route path="/">
            <MainApp />
          </Route>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );

  // keep track of page changes
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/auth" ||
      location.pathname === "/login-or-register"
    )
      return;
    localStorage.setItem("redirect", location.pathname);
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //schedule a redirect if coming back from login
    if (location.search.includes("?code=")) {
      redirectLocation.current = localStorage.getItem("redirect");
    }

    //execute a redirect after login is done
    if (isAuthenticated && redirectLocation.current) {
      history.replace(redirectLocation.current);
      redirectLocation.current = null;
    }
  }, [location, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingAuthentication) return <CircularProgress />;

  let authenticationRoute = (
    <Route exact path="/">
      {location.search.includes("?code=") ? (
        () => <SignIn />
      ) : location.search.includes("?signup=") ? (
        <SignUp />
      ) : (
        //send users here after they logout
        <Redirect to="/news" />
      )}
    </Route>
  );

  if (isAuthenticated) return AuthenticatedRoutes;

  if (location.search.includes("?error=")) {
    authenticationRoute = (
      <Route exact path="/">
        <AuthError />
      </Route>
    );
  }

  const publicRoutes = (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={translation[currentAppLocale.locale]}
        onError={(err) => {
          if (err.code === "MISSING_TRANSLATION") {
            return;
          }
          throw err;
        }}
      >
        <Wrapper>
          <div className="gx-main-content-wrapper no-trans">
            <Switch>
              <Route exact path="/auth" component={SignIn} />
              <Route exact path="/home" component={Home} />
              <Route
                exact
                path="/login-or-register"
                component={LoginOrRegister}
              />
              <Route
                exact
                path="/register-success"
                component={RegisterSuccess}
              />
              <Route path="/orders/:slug" component={Order} />
              <Route path="/shipyards/:slug" component={Shipyard} />
              <Route path="/company-group/:slug" component={CompanyGroup} />
              <Route path="/orderers/:slug" component={Orderer} />
              <Route path="/vessels/:slug" component={Vessel} />
              <Route path="/search" component={Search} />
              <Route exact path="/news" component={News} />
              <Route exact path={`/guide/construction`} component={ConstructionGuide} />
              <Route exact path={`/guide/recycling`} component={RecyclingGuide} />
              <Route exact path={`/payment-success`} component={PaymentSuccess} />
              {authenticationRoute}
              <Route exact path="*">
                <SignIn />
              </Route>
            </Switch>
          </div>
        </Wrapper>
      </IntlProvider>
    </ConfigProvider>
  );

  return publicRoutes;
};

export default memo(App);
