import { apiRequest } from "../actions/api.action";
import {
  FETCH_NEW_BUILDING_SHIPYARDS,
  FETCH_NEW_BUILDING_SHIPYARDS_FAILURE,
  FETCH_NEW_BUILDING_SHIPYARDS_SUCCESS,
  FETCH_NEW_BUILDING_SHIPYARD_STAT,
  FETCH_NEW_BUILDING_SHIPYARD_STAT_FAILURE,
  FETCH_NEW_BUILDING_SHIPYARD_STAT_SUCCESS,
} from "../actions";
import { getHost } from "../../util/host";

export const mapFlow =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        next(action);
        if (action.type === FETCH_NEW_BUILDING_SHIPYARDS) {
          const { boundingBox, isAuthenticated, vesselType, date_from, currentTab, newsIds } = action.payload;

          let baseUrl = `${getHost()}/new-ships-api/${isAuthenticated ? "user" : "public"}/recommendation?vesselType=${vesselType}`;

          if (boundingBox?._sw?.lng) {
            baseUrl += `&left=${boundingBox._sw.lng}&right=${boundingBox._ne.lng}&top=${boundingBox._ne.lat}&bottom=${boundingBox._sw.lat}&currentTab=${currentTab}&newsIds=${newsIds}`;
          }

          if (date_from !== undefined) {
            baseUrl += `&date_from=${date_from}`;
          }
          dispatch(
            apiRequest(
              "GET",
              baseUrl,
              null,
              FETCH_NEW_BUILDING_SHIPYARDS_SUCCESS,
              FETCH_NEW_BUILDING_SHIPYARDS_FAILURE
            )
          );
        }

        if (action.type === FETCH_NEW_BUILDING_SHIPYARD_STAT) {
          let url = `${getHost()}/new-ships-api/public/recommendation/${action.payload.shipyardId
            }/stat`;
          dispatch(
            apiRequest(
              "GET",
              url,
              null,
              FETCH_NEW_BUILDING_SHIPYARD_STAT_SUCCESS,
              FETCH_NEW_BUILDING_SHIPYARD_STAT_FAILURE
            )
          );
        }
      };

export const mapMdl = [mapFlow];
