import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Col, Divider, Radio, Row, Table, Tag, Tabs } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import CircularProgress from "../../components/CircularProgress";
import Widget from "../../components/Widget";
import { useDispatch, useSelector } from "react-redux";

import usePageQueries from "./queries";
import usePageQueriesHome from "../../routes/Home/queries";

import moment from "moment";
import { Link } from "react-router-dom";


import _ from "lodash";
import { useLocation } from "react-router-dom";
import NeedLoginCard from "../../components/NeedLoginCard";
import ContentAuthorization from "../ContentAuthorization";
import NeedAddonCard from "../../components/NeedAddonCard";

const descriptions = {
  order: "Shipbuilding orders that have been placed in the last 3 months",
  all_orders: "All shipbuilding orders",
  delivered: "All ships that have been delivered in the latest month",
  expected:
    "All ships that are currently launched and almost ready for delivery",
  builders: "All ship builders worldwide",
  orderers: "All ship orderers worldwide",
  news: "Most recent news in shipbuilding industry",
  recycling: "Scrappings that have been done in the last 6 months",
};

const ConstructionGuide = () => {

  const { isAuthenticated } = useAuth0();

  const location = useLocation();

  const [currentTag, setCurrentTag] = useState(null);
  const [currentTab, setCurrentTab] = useState("news");
  const [currentVesselType, setCurrentVesselType] = useState("");

  const [loader, setLoader] = useState(false);
  let vesselTypes = useSelector(({ vessel }) => vessel.vesselTypes);

  const [tableVariables, setTableVariables] = React.useState({
    pagination: { current: 1, pageSize: 10 },
    sort: {
      column: {
        title: "Last Update",
        dataIndex: "lastUpdate",
        key: "lastUpdate",
      },
      order: "descend",
      field: "lastUpdate",
      columnKey: "lastUpdate",
    },
  });

  const [deliversTableVariables, setDeliversTableVariables] = React.useState({
    pagination: { current: 1, pageSize: 10 },
    sort: {
      order: "descend",
      field: "lastUpdate",
    },
  });

  const [deliveredTableVariables, setDeliveredTableVariables] = React.useState({
    pagination: { current: 1, pageSize: 10 },
    sort: {
      order: "descend",
      field: "lastUpdate",
    },
  });

  const [buildersTableVariables, setBuildersTableVariables] = React.useState({
    pagination: { current: 1, pageSize: 10 },
    sort: {
      order: "descend",
      field: "onOrder",
    },
  });

  const handleBuildersTableChange = (pagination, filters, sort, extra) => {
    setBuildersTableVariables({
      ...buildersTableVariables,
      sort: {
        ...buildersTableVariables.sort,
        ...sort,
      },
      pagination: {
        ...buildersTableVariables.pagination,
        ...pagination,
      },
    });
  };

  const {
    tags,
    builders, isBuildersFetching
  } = usePageQueries({ isAuthenticated, currentTag, buildersTableVariables });

  const { delivered, delivers, permissions, data, news, latestOrders, recyclingData } =
  usePageQueriesHome({
    currentTab,
    typeId: currentTag?.id,
    currentVesselType,
    isAuthenticated,
    tableVariables,
    deliversTableVariables,
    deliveredTableVariables,
    orderersTableVariables: deliveredTableVariables,
    buildersTableVariables
  });

  useEffect(() => {
    if (tags) {
      const params = new URLSearchParams(location.search);
      const pId = params.get("tagGroupId");

      if (pId) {
        const tag = tags.find(t => t.id === +pId);
        if (tag) {
          setCurrentTag(tag);
          setCurrentVesselType(tag.name);
        } else {
          setCurrentTag(tags[0]);
          setCurrentVesselType(tags[0].name);
        }
      } else {
        setCurrentTag(tags[0]);
        setCurrentVesselType(tags[0].name);
      }
    }
  }, [tags, location.search]);

  const handleChange = (e) => {
    const value = e.target.value;
    setCurrentTag(value)
    setCurrentVesselType(value.name);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  };


  useEffect(() => {
    setTableVariables({
      ...tableVariables,
      filters: { vesselType: currentVesselType },
    });
  }, [currentVesselType]);

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const ordersTableData = data || [];

  const pagePermissions = permissions || {
    nso_order_home: { show: true, mask: true },
  };

  const orderColumns = [
    {
      title: "Last Update",
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.lastUpdate ? new Date(a.lastUpdate).getTime() : 0) -
        (b.lastUpdate ? new Date(b.lastUpdate).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Publish Date",
      dataIndex: "publishDate",
      key: "publishDate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.publishDate ? new Date(a.publishDate).getTime() : 0) -
        (b.publishDate ? new Date(b.publishDate).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Vessel Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span className="gx-link">
          <a
            href={`/app/orders/${record.id}-${_.kebabCase(text)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text ? text : "-"}
          </a>
          {record.premium && (
            <img
              className="pl10"
              style={{ height: "30px" }}
              src="/app/assets/images/premium_user_small.png"
              alt="premium-user-small"
            />
          )}
        </span>
      ),
      sorter: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      title: "Vessel Type",
      dataIndex: "tdVesselType",
      key: "tdVesselType",
      render: (text, record) => (
        <>
          {record?.tagGroupIds?.map((t, index) => {
            return (
              <Fragment key={`tag_gropu_${index}`}>
                {index > 0 && <>, </>}
                <a
                  href={`/app/guide/construction?tagGroupId=${t}`}
                  target="_blank"
                >
                  {vesselTypes.filter((vt) => vt.id === t).map((vt) => vt.name)}
                </a>
              </Fragment>
            );
          })}
        </>
      ),
      sorter: (a, b) =>
        a.tdVesselType
          .toLowerCase()
          .localeCompare(b.tdVesselType.toLowerCase()),
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
      sorter: (a, b) => (a.length || 0) - (b.length || 0),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.orderDate ? new Date(a.orderDate).getTime() : 0) -
        (b.orderDate ? new Date(b.orderDate).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.deliveryDate ? new Date(a.deliveryDate).getTime() : 0) -
        (b.deliveryDate ? new Date(b.deliveryDate).getTime() : 0),
    },
    {
      title: "Orderer",
      dataIndex: "orderer",
      key: "orderer",
      render: (text, record) => (
        <ContentAuthorization
          sectionId="new-ships-home"
          permission={pagePermissions.nso_order_home}
          showButton={false}
        >
          {record.orderer &&
            !record.orderer.name.toLowerCase().includes("unknown") && (
              <a
                href={`/app/orderers/${record.orderer.idStr}-${_.kebabCase(
                  record.orderer.name
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {record.orderer.country && (
                  <span
                    style={{
                      border: "2px solid #038FDE",
                      backgroundColor: "#038FDE",
                      height: "22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      title={record.orderer.country?.name}
                      className={`flag flag-24 flag-${_.lowerCase(
                        record.orderer.country?.a2
                      )}`}
                    />
                  </span>
                )}<span>{record.orderer.name}</span>
              </a>
            )
          }
        </ContentAuthorization >
      ),
      sorter: (a, b) =>
        a.shipyard.name
          .toLowerCase()
          .localeCompare(b.shipyard.name.toLowerCase()),
    },
    {
      title: "Shipyard",
      dataIndex: "shipyard",
      key: "shipyard",
      render: (text, record) => (
        <span className="gx-link">
          {record.shipyard && (
            <a
              href={`/app${record.shipyard.url}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {record.shipyard?.country && (
                <span
                  style={{
                    border: "2px solid #038FDE",
                    backgroundColor: "#038FDE",
                    height: "22px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    title={record.shipyard?.country?.name}
                    className={`flag flag-24 flag-${_.lowerCase(
                      record.shipyard?.country?.a2
                    )}`}
                  />
                </span>
              )}<span>
                {record.shipyard.name}
              </span>
            </a>
          )}
        </span>
      ),
      sorter: () => { },
    },
  ];

  const deliveredColumns = [
    {
      title: "AIS Position Time",
      dataIndex: "positionTimestamp",
      key: "positionTimestamp",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).fromNow() : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.positionTimestamp ? new Date(a.positionTimestamp).getTime() : 0) -
        (b.positionTimestamp ? new Date(b.positionTimestamp).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Vessel Name",
      dataIndex: "vesselName",
      key: "vesselName",
      render: (text, record) => (
        <a
          href={`/app/orders/${record.id}-${_.kebabCase(record.name)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text ? text : "YARD: " + record?.shipyard?.name}
        </a>
      ),
    },
    // {
    //   title: "Vessel Type",
    //   dataIndex: "tdVesselType",
    //   key: "tdVesselType",
    //   render: (text, record) => (
    //     <>
    //       {record?.tagGroupIds?.map((t, index) => {
    //         return (
    //           <Fragment key={`tag_gropu_${index}`}>
    //             {index > 0 && <>, </>}
    //             <a
    //               href={`/app/guide/construction?tagGroupId=${t}`}
    //               target="_blank"
    //             >
    //               {vesselTypes.filter((vt) => vt.id === t).map((vt) => vt.name)}
    //             </a>
    //           </Fragment>
    //         );
    //       })}
    //     </>
    //   ),
    //   sorter: (a, b) =>
    //     a.tdVesselType
    //       .toLowerCase()
    //       .localeCompare(b.tdVesselType.toLowerCase()),
    // },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
      sorter: (a, b) => (a.length || 0) - (b.length || 0),
      defaultSortOrder: "descend",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.orderDate ? new Date(a.orderDate).getTime() : 0) -
        (b.orderDate ? new Date(b.orderDate).getTime() : 0),
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.deliveryDate ? new Date(a.deliveryDate).getTime() : 0) -
        (b.deliveryDate ? new Date(b.deliveryDate).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Orderer",
      dataIndex: "orderer",
      key: "orderer",
      render: (text, record) => (
        <ContentAuthorization
          sectionId="new-ships-home"
          permission={pagePermissions.nso_order_home}
          showButton={false}
        >
          {record.orderer &&
            !record.orderer.name.toLowerCase().includes("unknown") && (
              <a
                href={`/app/orderers/${record.orderer.idStr}-${_.kebabCase(
                  record.orderer.name
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {record.orderer.country && (
                  <span
                    style={{
                      border: "2px solid #038FDE",
                      backgroundColor: "#038FDE",
                      height: "22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      title={record.orderer.country?.name}
                      className={`flag flag-24 flag-${_.lowerCase(
                        record.orderer.country?.a2
                      )}`}
                    />
                  </span>
                )}<span>{record.orderer.name}</span>
              </a>
            )
          }
        </ContentAuthorization >
      ),
      sorter: (a, b) =>
        a.shipyard.name
          .toLowerCase()
          .localeCompare(b.shipyard.name.toLowerCase()),
    },
    {
      title: "Shipyard",
      dataIndex: "shipyard",
      key: "shipyard",
      render: (text, record) => (
        <span className="gx-link">
          {record.shipyard && (
            <a
              href={`/app${record.shipyard.url}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {record.shipyard?.country && (
                <span
                  style={{
                    border: "2px solid #038FDE",
                    backgroundColor: "#038FDE",
                    height: "22px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    title={record.shipyard?.country?.name}
                    className={`flag flag-24 flag-${_.lowerCase(
                      record.shipyard?.country?.a2
                    )}`}
                  />
                </span>
              )}<span>
                {record.shipyard.name}
              </span>
            </a>
          )}
        </span>
      ),
      sorter: (a, b) =>
        a.shipyard.name
          .toLowerCase()
          .localeCompare(b.shipyard.name.toLowerCase()),
    },
  ];

  const deliversColumns = [
    {
      title: "AIS Position Time",
      dataIndex: "positionTimestamp",
      key: "positionTimestamp",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).fromNow() : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.positionTimestamp ? new Date(a.positionTimestamp).getTime() : 0) -
        (b.positionTimestamp ? new Date(b.positionTimestamp).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Vessel Name",
      dataIndex: "vesselName",
      key: "vesselName",
      render: (text, record) => (
        <a
          href={`/app/orders/${record.id}-${_.kebabCase(record.name)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text ? text : "YARD: " + record?.shipyard?.name}
        </a>
      ),
    },
    // {
    //   title: "Vessel Type",
    //   dataIndex: "tdVesselType",
    //   key: "tdVesselType",
    //   render: (text, record) => (
    //     <>
    //       {record?.tagGroupIds?.map((t, index) => {
    //         return (
    //           <Fragment key={`tag_gropu_${index}`}>
    //             {index > 0 && <>, </>}
    //             <a
    //               href={`/app/guide/construction?tagGroupId=${t}`}
    //               target="_blank"
    //             >
    //               {vesselTypes.filter((vt) => vt.id === t).map((vt) => vt.name)}
    //             </a>
    //           </Fragment>
    //         );
    //       })}
    //     </>
    //   ),
    //   sorter: (a, b) =>
    //     a.tdVesselType
    //       .toLowerCase()
    //       .localeCompare(b.tdVesselType.toLowerCase()),
    // },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
      sorter: (a, b) => (a.length || 0) - (b.length || 0),
      defaultSortOrder: "descend",
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.orderDate ? new Date(a.orderDate).getTime() : 0) -
        (b.orderDate ? new Date(b.orderDate).getTime() : 0),
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (d) => {
        return (
          <ContentAuthorization
            sectionId="new-ships-home"
            permission={pagePermissions.nso_order_home}
            showButton={false}
          >
            <>{d ? moment(d).format("DD.MMM.YYYY") : ""}</>
          </ContentAuthorization>
        );
      },
      sorter: (a, b) =>
        (a.deliveryDate ? new Date(a.deliveryDate).getTime() : 0) -
        (b.deliveryDate ? new Date(b.deliveryDate).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Orderer",
      dataIndex: "orderer",
      key: "orderer",
      render: (text, record) => (
        <ContentAuthorization
          sectionId="new-ships-home"
          permission={pagePermissions.nso_order_home}
          showButton={false}
        >
          {record.orderer &&
            !record.orderer.name.toLowerCase().includes("unknown") && (
              <a
                href={`/app/orderers/${record.orderer.idStr}-${_.kebabCase(
                  record.orderer.name
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {record.orderer.country && (
                  <span
                    style={{
                      border: "2px solid #038FDE",
                      backgroundColor: "#038FDE",
                      height: "22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      title={record.orderer.country?.name}
                      className={`flag flag-24 flag-${_.lowerCase(
                        record.orderer.country?.a2
                      )}`}
                    />
                  </span>
                )}<span>{record.orderer.name}</span>
              </a>
            )
          }
        </ContentAuthorization >
      ),
      sorter: (a, b) =>
        a.shipyard.name
          .toLowerCase()
          .localeCompare(b.shipyard.name.toLowerCase()),
    },
    {
      title: "Shipyard",
      dataIndex: "shipyard",
      key: "shipyard",
      render: (text, record) => (
        <span className="gx-link">
          {record.shipyard && (
            <a
              href={`/app${record.shipyard.url}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {record.shipyard?.country && (
                <span
                  style={{
                    border: "2px solid #038FDE",
                    backgroundColor: "#038FDE",
                    height: "22px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    title={record.shipyard?.country?.name}
                    className={`flag flag-24 flag-${_.lowerCase(
                      record.shipyard?.country?.a2
                    )}`}
                  />
                </span>
              )}<span>
                {record.shipyard.name}
              </span>
            </a>
          )}
        </span>
      ),
      sorter: (a, b) =>
        a.shipyard.name
          .toLowerCase()
          .localeCompare(b.shipyard.name.toLowerCase()),
    },
  ];

  const newsColumns = [
    {
      title: "",
      dataIndex: "news",
      key: "news.image",
      render: (news) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(news.content, 'text/html');

        const imgElement = doc.querySelector('img');
        const imgSrc = imgElement ? imgElement.src : null;


        return (
          <>
            <img
              src={imgSrc ? imgSrc : "/app/assets/images/logo_w_text.png"}
              alt="Ship Image"
              style={{ maxWidth: '150px', height: 'auto', objectFit: 'cover', borderRadius: '4px', boxShadow: '0 1px 1px rgba(0,0,0,0.1)' }}
            />
          </>
        );
      }
    },
    {
      title: "Published",
      dataIndex: "news",
      key: "news.date",
      render: (news) => {
        return (
          (<>{news.creationdate ? moment(news.creationdate).fromNow() : ""}</>)
        );
      },
      sorter: (a, b) =>
        (a.lastUpdate ? new Date(a.lastUpdate).getTime() : 0) -
        (b.lastUpdate ? new Date(b.lastUpdate).getTime() : 0),
      defaultSortOrder: "decend",
    },
    {
      title: "Title",
      dataIndex: "news",
      key: "news.tags",
      render: (news) => {
        return (
          <Link to={`/news/${news.id}`} target="_blank" rel="noopener noreferrer">{news.title}</Link>
        );
      }
    },
    {
      title: "Type",
      dataIndex: "shipTypes",
      key: "shipTypes",
      render: (types) => {
        return {
          children: <>
            {types?.map((type, index) => {
              return (
                <div key={`type_${index}`} style={{ marginBottom: '10px' }}>
                  <Link to={`guide/construction/?tagGroupId=${type.id}`} target="_blank" rel="noopener noreferrer">{type.name}</Link>
                </div>
              );
            })}
          </>
        };
      },
    },
    {
      title: <div style={{ width: '100%', textAlign: 'right' }}>Related</div>,
      dataIndex: "companies",
      key: "news.companies",
      render: (companies) => {
        return {
          children: <>
            {companies?.map((company, index) => {
              return (
                <div key={`company_${index}`} style={{ marginBottom: '10px', textAlign: 'right' }}>
                  <Link to={`/shipyards/${company.id}`} target="_blank" rel="noopener noreferrer">{company.name}</Link>
                </div>
              );
            })}
          </>
        };
      }
    },
  ];

  const recyclingColumns = [
    {
      title: "Recycling Yard",
      dataIndex: "shipyard",
      key: "shipyard",
      render: (text, record) => (
        <>
          {record.shipyard.name}
        </>
      ),
      sorter: (a, b) =>
        a.shipyard.name
          .toLowerCase()
          .localeCompare(b.shipyard.name.toLowerCase()),
    },
    {
      title: "Imo",
      dataIndex: "imo",
      sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
      render: (text, record) => (
        <>
          {text}
        </>
      ),
    },
    {
      title: "Vessel Name",
      dataIndex: "name",
      sorter: (a, b) => (a.imo > b.imo ? 1 : -1),
      render: (text, record) => (
        <>
          <a href={`https://www.trusteddocks.com/vessel/${record.imo}`} target="_blank">{text}</a>
        </>
      ),
    },
    {
      title: "Vessel Type",
      dataIndex: "event",
      render: (event) => (
        <>
          {event.vesselVo.shipType}
        </>
      ),
      sorter: (a, b) => a.tdVesselType > b.tdVesselType,
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
      defaultSortOrder: "descend",
      sorter: (a, b) => (a["length"] > b["length"] ? 1 : -1),
    }
  ];

  const handleOnOrderTableChange = (pagination, filters, sort) => {
    setTableVariables({
      ...tableVariables,
      sort: {
        ...tableVariables.sort,
        ...sort,
      },
      pagination: {
        ...tableVariables.pagination,
        ...pagination,
      },
    });
  };

  const handleDeliversTableChange = (pagination, filters, sort) => {
    setDeliversTableVariables({
      ...deliversTableVariables,
      sort: {
        ...deliversTableVariables.sort,
        ...sort,
      },
      pagination: {
        ...deliversTableVariables.pagination,
        ...pagination,
      },
    });
  };

  const handleDeliveredTableChange = (pagination, filters, sort) => {
    setDeliveredTableVariables({
      ...deliveredTableVariables,
      sort: {
        ...deliveredTableVariables.sort,
        ...sort,
      },
      pagination: {
        ...deliveredTableVariables.pagination,
        ...pagination,
      },
    });
  };


  const shipyardColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <span className="gx-link">
          {record.country &&
            <span style={{ border: "2px solid #038FDE", backgroundColor: "#038FDE" }}>
              <i
                style={{ marginBottom: "-7px" }}
                title={record.country?.name}
                className={`flag flag-24 flag-${_.lowerCase(
                  record.country?.a2
                )}`}
              />
            </span>
          }
          {!record.country &&
            <span style={{ border: "2px solid #038FDE", backgroundColor: "#038FDE" }}>
              <i
                style={{ marginBottom: "-7px" }}
                title={record.country?.name}
                className={`flag flag-24 flag-unknown`}
              />
            </span>
          }
          <a className="ml10"
            href={`/app${record.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
          {record.premium && (
            <img
              className="pl10"
              style={{ height: "30px" }}
              src="/app/assets/images/premium_user_small.png"
              alt="premium user"
            />
          )}
        </span>
      ),
      sorter: (a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }
    },
    {
      title: "On Order",
      dataIndex: "onOrder",
      key: "onOrder",
      sorter: (a, b) => (a.onOrder || 0) - (b.onOrder || 0),
      defaultSortOrder: "decend",
      render: (text, record, index) => {
        return text === 0 ? "" : text
      }
    },
    {
      title: "Delivered last 6 month",
      dataIndex: "delivered_6",
      key: "delivered_6",
      sorter: (a, b) => (a.delivered_6 || 0) - (b.delivered_6 || 0),
      render: (text, record, index) => {
        return text === 0 ? "" : text
      }
    },
    {
      title: "Delivered last 12 month",
      dataIndex: "delivered_12",
      key: "delivered_12",
      sorter: (a, b) => (a.delivered_12 || 0) - (b.delivered_12 || 0),
      render: (text, record, index) => {
        return text === 0 ? "" : text
      }
    },
    {
      title: "Delivered last 24 month",
      dataIndex: "delivered_24",
      key: "delivered_24",
      sorter: (a, b) => (a.delivered_24 || 0) - (b.delivered_24 || 0),
      render: (text, record, index) => {
        return text === 0 ? "" : text
      }
    },
    {
      title: "Delivery Expected",
      dataIndex: "inBuilt",
      key: "inBuilt",
      sorter: (a, b) => {
        return (a.inBuilt || 0) - (b.inBuilt || 0);
      },
      render: (text, record, index) => {
        return text === 0 ? "" : text
      }
    },
    {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
      sorter: (a, b) => {
        return (a.delivered || 0) - (b.delivered || 0);
      },
      render: (text, record, index) => {
        return text === 0 ? "" : text
      }
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Widget>
          <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
            {currentTag &&
              <Radio.Group
                className="gx-radio-group-link gx-radio-group-link-news"
                defaultValue={currentTag}
                onChange={handleChange}>
                {tags &&
                  tags.map((t, index) => {
                    return (
                      <Radio.Button key={`tag_${t.id}`} value={t} className="gx-mb-2">{t.name}</Radio.Button>
                    )
                  })
                }
              </Radio.Group>
            }
          </div>

          <Tabs onChange={handleTabChange} type="card" defaultValue={'all_orders'}>
              <Tabs.TabPane
                tab={`Recent News (${news?.total || "0"})`}
                key="news"
              >
                {!currentVesselType && (
                  <>
                    <Alert message={`${descriptions["news"]}.`} type="info" />
                  </>
                )}
                {currentVesselType && (
                  <>
                    <Alert
                      message={`${descriptions["news"]} for ${currentVesselType}.`}
                      type="info"
                    />
                  </>
                )}

                {news?.data && (
                  <Table
                    className="gx-table-responsive"
                    columns={newsColumns}
                    sortDirections={["ascend", "descend", "ascend"]}
                    rowKey="id"
                    dataSource={news?.data}
                    onChange={handleOnOrderTableChange}
                    pagination={{
                      total: news?.total,
                      defaultPageSize: tableVariables.pagination.pageSize,
                      showSizeChanger: false,
                    }}
                  />
                )}
              </Tabs.TabPane>
            <Tabs.TabPane
              tab={`New Orders (${latestOrders?.total || "0"})`}
              key="new_orders"
            >
              {!currentVesselType && (
                <>
                  <Alert message={`${descriptions["order"]}.`} type="info" />
                </>
              )}
              {currentVesselType && (
                <>
                  <Alert
                    message={`${descriptions["order"]} for ${currentVesselType}.`}
                    type="info"
                  />
                </>
              )}

              {!isAuthenticated &&
                <NeedLoginCard />
              }

              {isAuthenticated && pagePermissions.nso_order_details?.mask &&
                <NeedAddonCard />
              }

              {isAuthenticated && !pagePermissions.nso_order_details?.mask && latestOrders && (
                <Table
                  className="gx-table-responsive"
                  columns={orderColumns}
                  sortDirections={["ascend", "descend", "ascend"]}
                  rowKey="id"
                  dataSource={latestOrders.data}
                  onChange={handleOnOrderTableChange}
                  pagination={{
                    total: latestOrders.total,
                    defaultPageSize: tableVariables.pagination.pageSize,
                    showSizeChanger: false,
                  }}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={`All orders (${ordersTableData?.total || "0"})`}
              key="all_orders"
            >
              {!currentVesselType && (
                <>
                  <Alert message={`${descriptions["all_orders"]}.`} type="info" />
                </>
              )}
              {currentVesselType && (
                <>
                  <Alert
                    message={`${descriptions["all_orders"]} for ${currentVesselType}.`}
                    type="info"
                  />
                </>
              )}

              {!isAuthenticated &&
                <NeedLoginCard />
              }

              {isAuthenticated && pagePermissions.nso_order_details?.mask &&
                <NeedAddonCard />
              }

              {isAuthenticated && !pagePermissions.nso_order_details?.mask && ordersTableData && (
                <Table
                  className="gx-table-responsive"
                  columns={orderColumns}
                  sortDirections={["ascend", "descend", "ascend"]}
                  rowKey="id"
                  dataSource={ordersTableData.data}
                  onChange={handleOnOrderTableChange}
                  pagination={{
                    total: ordersTableData.total,
                    defaultPageSize: tableVariables.pagination.pageSize,
                    showSizeChanger: false,
                  }}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={`Latest Deliveries (${delivered?.total || "0"})`}
              key="delivered"
            >
              {!currentVesselType && (
                <>
                  <Alert message={`${descriptions["delivered"]}.`} type="info" />
                </>
              )}
              {currentVesselType && (
                <>
                  <Alert
                    message={`${descriptions["delivered"]} for ${currentVesselType}.`}
                    type="info"
                  />
                </>
              )}

              {!isAuthenticated &&
                <NeedLoginCard />
              }

              {isAuthenticated && pagePermissions.nso_order_details?.mask &&
                <NeedAddonCard />
              }

              {isAuthenticated && !pagePermissions.nso_order_details?.mask && delivered &&
                <Table
                  className="gx-table-responsive"
                  columns={deliveredColumns}
                  sortDirections={["ascend", "descend", "ascend"]}
                  rowKey="id"
                  onChange={handleDeliveredTableChange}
                  dataSource={delivered?.data}
                  pagination={{
                    showSizeChanger: false,
                    total: delivered?.total,
                    defaultPageSize: deliveredTableVariables.pagination.pageSize,
                  }}
                />
              }
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={`Delivery Expected (${delivers?.total || "0"})`}
              key="expected"
            >
              {!currentVesselType && (
                <>
                  <Alert message={`${descriptions["expected"]}.`} type="info" />
                </>
              )}
              {currentVesselType && (
                <>
                  <Alert
                    message={`${descriptions["expected"]} for ${currentVesselType}.`}
                    type="info"
                  />
                </>
              )}

              {!isAuthenticated &&
                <NeedLoginCard />
              }

              {isAuthenticated && pagePermissions.nso_order_details?.mask &&
                <NeedAddonCard />
              }

              {isAuthenticated && !pagePermissions.nso_order_details?.mask && delivers &&
                <Table
                  className="gx-table-responsive"
                  columns={deliversColumns}
                  sortDirections={["ascend", "descend", "ascend"]}
                  rowKey="id"
                  onChange={handleDeliversTableChange}
                  dataSource={delivers?.data}
                  pagination={{
                    showSizeChanger: false,
                    total: delivers?.total,
                    defaultPageSize: deliversTableVariables.pagination.pageSize,
                  }}
                />
              }
            </Tabs.TabPane>
          </Tabs>
        </Widget>

        <Widget>
          {isBuildersFetching && <CircularProgress className="gx-loader-400" />}

          {builders?.total > 0 && !isBuildersFetching &&
            <>
              <Alert message={`Shipyards building ${currentTag.name}`} type="info" />
              {isAuthenticated ? <Table
                className="gx-table-responsive"
                columns={shipyardColumns}
                rowKey="id"
                dataSource={builders?.data}
                sortDirections={["ascend", "descend", "ascend"]}
                // onChange={handleBuildersTableChange}
                pagination={{
                  showSizeChanger: false,
                  total: builders?.total,
                  defaultPageSize: buildersTableVariables.pagination.pageSize,
                }}
              /> : <ContentAuthorization />}
            </>
          }
        </Widget>
      </Col>
    </Row>

  )
};

export default ConstructionGuide;
