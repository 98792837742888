import React from "react";
import Widget from "components/Widget/index";
import { Button } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NeedLoginCard = () => {
  return (
    <Widget styleName="gx-bg-dark-primary">
      <div className="gx-flex-row gx-justify-content-center gx-mb-3 gx-mb-md-4 gx-p-3">
        <i className={`icon icon-refer gx-fs-xlxl gx-text-white`} />
      </div>
      <div className="gx-text-center">
        <h2 className="h3 gx-mb-3 gx-text-white">Please Log In or Register</h2>
        <p className="gx-text-white gx-mb-3">The following content is available only for registered users, please log in to your account or register to gain access.</p>
        <Link to="/login-or-register">
          <Button size="large" className="gx-btn-secondary gx-mt-md-5 gx-mb-1">Login / Register</Button>
        </Link>
      </div>
    </Widget>
  );
};

export default NeedLoginCard;
