import { useMutation, useQuery, useQueryClient } from "react-query";
import request from "../../../util/request";

const QUERY_KEYS = {
  USER_INFO: "user-info",
  COMPANIES: "companies",
  WORKINGHISTORIES: "working-history"
};

const usePageQueries = ({ isAuthenticated }) => {

  const queryClient = useQueryClient()

  const {
    data: userInfo,
  } = useQuery(
    QUERY_KEYS.USER_INFO,
    async () => {
      const response = await request({
        url: `new-ships-api/user/user-info`,
        method: "GET",
      });
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  

  return {
    userInfo,
   
  };
};

export default usePageQueries;
