import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const pathname = useSelector(({ common }) => common.pathname);
  const { isAuthenticated } = useAuth0();

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      theme="dark"
      mode="horizontal"
    >
      <Menu.Item key="news">
        <Link to="/news">
          News
        </Link>
      </Menu.Item>

      <Menu.Item key="home">
        <Link to="/home">
          {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
          Orderbook
        </Link>
      </Menu.Item>

      <Menu.Item key="search">
        <Link to="/search">
          {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
          Order Search
        </Link>
      </Menu.Item>

      <Menu.Item key="statistics">
        <Link to="/statistics">
          {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
          Statistics
        </Link>
      </Menu.Item>

      <SubMenu
        key="tracker/"
        title={
          <span>
            <span>
              {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
              Live Tracker
            </span>
          </span>
        }
      >
        <Menu.Item
          key="tracker/new-build"
          theme="dark"
        >
          <Link to="/tracker/new-build">
            {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
            New Builds
          </Link>
        </Menu.Item>
        <Menu.Item
          key="tracker/recycling"
          theme="dark"
        >
          <Link to="/tracker/recycling">
            {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
            Scrappings
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="/guide"
        title={
          <span>
            <span>
              Guide
            </span>
          </span>
        }
      >
        <Menu.Item key="guide/construction">
          <Link to="/guide/construction">
            Construction Guide
          </Link>
        </Menu.Item>
        <Menu.Item key="guide/recycling">
          <Link to="/guide/recycling">
            Recycling Guide
          </Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="about">
        <a href="https://new-ships-discover.trusteddocks.com" target={"_blank"}>
          About
        </a>
      </Menu.Item>

    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

