export const FETCH_SHIPYARD_BY_ID = "fetch shipyard by id"
export const FETCH_SHIPYARD_BY_ID_SUCCESS = "fetch shipyard by id success"
export const FETCH_SHIPYARD_BY_ID_FAILURE = "fetch shipyard by id failure"

export const fetchShipyardById = (data) => ({
  type: FETCH_SHIPYARD_BY_ID,
  payload: data
});







