import React, { useEffect } from "react";
import CircularProgress from "../components/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";

const SignUp = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({ screen_hint: "signup" });
  }, []); // eslint-disable-line

  return <CircularProgress />;
};

export default SignUp;
