import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
import { LockOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";

const SubMenu = Menu.SubMenu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const { isAuthenticated } = useAuth0();
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars
          className="gx-layout-sider-scrollbar"
          style={{ marginTop: "2rem", overflowY: "hidden" }}
        >
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme="dark"
            mode="inline"
          >
            <Menu.Item key="news">
              <Link to="/news">
                News
              </Link>
            </Menu.Item>

            <Menu.Item key="home">
              <Link to="/home">
              <span style={{ display: "flex", alignItems: "center" }}>
                  {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
                  <span>Orderbook</span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="search">
              <Link to="/search">
                <span style={{ display: "flex", alignItems: "center" }}>
                  {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
                  <span>Order Search</span>
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="statistics">
              <Link to="/statistics">
                <span style={{ display: "flex", alignItems: "center" }}>
                  {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
                  <span>Statistics</span>
                </span>
              </Link>
            </Menu.Item>

            <SubMenu
              key="sidebar.tracker"
              title={
                <span>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {!isAuthenticated ? <LockOutlined className="mr5" /> : null}
                    <span>Live Tracker</span>
                  </span>
                </span>
              }
            >
              <Menu.Item
                key="sidebar.tracker.new-build"
                selectedKeys={[selectedKeys]}
                theme="dark"
              >
                <Link to="/tracker/new-build">
                  <span>
                    New Builds
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="sidebar.tracker.recycling"
                selectedKeys={[selectedKeys]}
                theme="dark"
              >
                <Link to="/tracker/recycling">
                  <span>
                    Scrappings
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="sidebar.guide"
              title={
                <span>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span>Guide</span>
                  </span>
                </span>
              }
            >
              <Menu.Item key="sidebar.guide.constuction-guide">
                <Link to="/guide/construction">
                  Construction Guide
                </Link>
              </Menu.Item>
              <Menu.Item key="sidebar.guide.recycling-guide">
                <Link to="/guide/recycling">
                  Recycling Guide
                </Link>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="about">
              <a href="https://new-ships-discover.trusteddocks.com" target={"_blank"}>
                About
              </a>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
