import _ from "lodash";
import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import request from "../../util/request";
import moment from "moment";

const QUERY_KEYS = {
  ORDERBOOK: "order-book",
  VESSEL_TYPE: "vessel-type",
  COUNTRIES: "countries",
  SHIPYARD_PERMISSIONS: "nso_order_permissions",
};

const useSearchQueries = ({ tableVariables, isAuthenticated }) => {

  const queryClient = useQueryClient()

  const vesselTypesQuery = useQuery(
    QUERY_KEYS.VESSEL_TYPE,
    async () => {
      const response = await request({
        url: "new-ships-api/public/vessel/types",
        method: "GET",
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  const {
    data: permissions,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_PERMISSIONS,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/content-permissions`,
        method: "GET",
        params: {
          page: "nso_order",
        },
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  const continentsAndCountriesQuery = useQuery(
    QUERY_KEYS.COUNTRIES,
    async () => {
      const response = await request({
        url: "new-ships-api/public/country",
        method: "GET",
      });
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  const fetchSearch = useCallback(async () => {
    let filters = _.omitBy(tableVariables.filters, _.isNil);

    let shipyardContinent
    if (filters.shipyardContinent === "Asia") shipyardContinent = "AS"
    if (filters.shipyardContinent === "Europe") shipyardContinent = "EU"
    if (filters.shipyardContinent === "Oceania") shipyardContinent = "OC"
    if (filters.shipyardContinent === "North America") shipyardContinent = "NA"
    if (filters.shipyardContinent === "Africa") shipyardContinent = "AF"

    let ordererContinent
    if (filters.ordererContinent === "Asia") ordererContinent = "AS"
    if (filters.ordererContinent === "Europe") ordererContinent = "EU"
    if (filters.ordererContinent === "Oceania") ordererContinent = "OC"
    if (filters.ordererContinent === "North America") ordererContinent = "NA"
    if (filters.ordererContinent === "South America") ordererContinent = "SA"
    if (filters.ordererContinent === "Africa") ordererContinent = "AF"

    const params = {
      currentPage: tableVariables.pagination.current - 1,
      pageSize: tableVariables.pagination.pageSize,
      orderBy: tableVariables.sort.field,
      order: tableVariables.sort.order || "ascend",
      minLength: filters.length?.[0],
      maxLength: filters.length?.[1],
      minWidth: filters.width?.[0],
      maxWidth: filters.width?.[1],
      minDraught: filters.draught?.[0],
      maxDraught: filters.draught?.[1],
      orderStatus:
        filters.orderStatus?.join(",") || "ON_ORDER,Option,LOI,Rumours,DELIVER_EXPECTED",
      shipyardId: filters?.shipyardId,
      ordererId: filters?.ordererId,
      vesselType: filters.vesselType,
      orderDateStart: filters.orderDateStart ? moment(filters.orderDateStart * 1000).format('YYYY-MM-01') : null,
      orderDateEnd: filters.orderDateEnd ? moment(filters.orderDateEnd * 1000).endOf('month').format('YYYY-MM-DD') : null,
      deliveryDateStart: filters.deliveryDateStart ? moment(filters.deliveryDateStart * 1000).format('YYYY-MM-01') : null,
      deliveryDateEnd: filters.deliveryDateEnd ? moment(filters.deliveryDateEnd * 1000).endOf('month').format('YYYY-MM-DD') : null,
      typeOfDrive: filters.typeOfDrive?.map(t => _.snakeCase(t)).join(",") || "",
      minGt: filters.gt?.[0],
      maxGt: filters.gt?.[1],
      minDwt: filters.dwt?.[0],
      maxDwt: filters.dwt?.[1],
      minTeu: filters.teu?.[0],
      maxTeu: filters.teu?.[1],
      hullNo: filters.hullNo,
      imoNo: filters.imoNo,
      shipTypeSize: filters.shipTypeSize,
      machineryDesign: filters.machineryDesign,
      machineryType: filters.machineryType,
      minMachineryPower: filters.machineryPower?.[0],
      maxMachineryPower: filters.machineryPower?.[1],
      minServiceSpeed: filters.serviceSpeed?.[0],
      maxServiceSpeed: filters.serviceSpeed?.[1],
      strokeEngine: filters.strokeEngine,
      shipName: filters.shipName,
      shipNote: filters.shipNote,
      classificationSociety: filters.classificationSociety,
      iceClass: filters.iceClass,
      minReeferplugs: filters.reeferplugs?.[0],
      maxReeferplugs: filters.reeferplugs?.[1],
      minCapacityGrain: filters.capacityGrain?.[0],
      maxCapacityGrain: filters.capacityGrain?.[1],
      minCapacityBales: filters.capacityBales?.[0],
      maxCapacityBales: filters.capacityBales?.[1],
      minCapacityLiquid: filters.capacityLiquid?.[0],
      maxCapacityLiquid: filters.capacityLiquid?.[1],
      minPassenger: filters.passenger?.[0],
      maxPassenger: filters.passenger?.[1],
      minCars: filters.cars?.[0],
      maxCars: filters.cars?.[1],
      minTrailer: filters.trailer?.[0],
      maxTrailer: filters.trailer?.[1],
      minLaneMeter: filters.laneMeter?.[0],
      maxLaneMeter: filters.laneMeter?.[1],
      minBollardPull: filters.bollardPull?.[0],
      maxBollardPull: filters.bollardPull?.[1],
      cargohandling: filters.cargohandling,
      shipId: filters.shipId,
      shipyardContinent,
      shipyardCountry: filters.shipyardCountry,
      ordererContinent,
      ordererCountry: filters.ordererCountry,
      shipyardLocationExclude: filters.shipyardLocationExclude,
      ordererLocationExclude: filters.ordererLocationExclude,
    };

    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/order/search`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });
    return response;
  }, [tableVariables, isAuthenticated]);

  const {
    data,
    isError,
    refetch,
    isLoading: fetchSearchIsLoading,
  } = useQuery(QUERY_KEYS.ORDERBOOK, fetchSearch, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 300000,
    refetchOnWindowFocus: false,
  });

  const saveUserSearch = useMutation("saveUserSearch", async (values) => {
    const response = await request({
      url: `new-ships-api/user/searches`,
      data: {
        ...values,
      },
      method: "POST",
    });
    return response;
  }, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()
    }
  }
  );

  const {
    data: userSearches
  } = useQuery("userSearches", async (values) => {
    const response = await request({
      url: `new-ships-api/user/searches`,
      method: "GET",
    });
    return response;
  }, {
  }
  );

  const deleteByCompanyMailingId = useMutation("deleteByCompanyMailingId", async (values) => {
    const response = await request({
      url: `new-ships-api/user/searches/${values}`,
      method: "DELETE",
    });
    return response;
  }, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()
    }
  }
  );

  return {
    vesselTypesQuery,
    permissions,
    continentsAndCountriesQuery,
    data,
    isError,
    refetch,
    fetchSearchIsLoading,
    saveUserSearch,
    userSearches,
    deleteByCompanyMailingId
  };
};

export default useSearchQueries;
