import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


const SidebarLogo = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {themeType} = useSelector(({settings}) => settings);

  let navStyle = useSelector(({settings}) => settings.navStyle);
  const width = useSelector(({common}) => common.width);

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  const headerStyle = {
    height: width < 575 ? "80px" : '60px'
  };

  return (
    <div className="gx-layout-sider-header" style={headerStyle}>
      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">
        <i
          className={`gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            setSidebarCollapsed(!sidebarCollapsed)
          }}
        />
      </div> : null}

      <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
          <img alt="lo" src={("/app/assets/images/w-logo.png")}/> :
          themeType === THEME_TYPE_LITE ?
            <img alt="logo1" src={("/app/assets/images/logo-white.png")}/> :
            <img alt="logo2" src={("/app/assets/images/logo-white.png")} style={{height: "4rem"}}/>}
      </Link>
    </div>
  );
};

export default SidebarLogo;
