const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb'
  }, 
  {
    languageId: 'chinese',
    locale: 'zh',
    name: 'Chinese',
    icon: 'cn'
  }
];
export default languageData;
