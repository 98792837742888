import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import NeedLoginCard from "../../components/NeedLoginCard";
import NeedAddonCard from "../../components/NeedAddonCard";


const ContentAuthorization = ({ permission, children, sectionId, text, showButton = true }) => {

  const { isAuthenticated } = useAuth0();

  const [style, setStyle] = useState({
    display: "none",
    position: "absolute",
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    width: 'fit-content'
  });

  const { show, mask } = permission || { show: true, mask: true };

  let showText = text || isAuthenticated ? "Need Addon" : "Login / Register"

  if (!show) return null;

  if (!isAuthenticated && mask)
    return (
      <div
        style={{ position: "relative", cursor: "pointer" }} title={showText}
        onMouseEnter={(e) => {
          setStyle((prevStyle) => ({ ...prevStyle, display: "block" }));
        }}
        onMouseLeave={(e) => {
          setStyle((prevStyle) => ({ ...style, display: "none" }));
        }}
      >
        {showButton &&
          <NeedLoginCard />
        }
      </div>
    );

  if (isAuthenticated && mask)
    return (
      <div
        style={{ position: "relative", cursor: "pointer" }} title={showText}
        onMouseEnter={(e) => {
          setStyle((prevStyle) => ({ ...prevStyle, display: "block" }));
        }}
        onMouseLeave={(e) => {
          setStyle((prevStyle) => ({ ...style, display: "none" }));
        }}
      >
        {showButton &&
          <NeedAddonCard />
        }
      </div>
    );

  if (!mask) return <Fragment>{children}</Fragment>;
};

export default ContentAuthorization;
