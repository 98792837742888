import { AutoComplete, Row, Col, Input, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import request from "../../util/request";
import _ from "lodash";

const QUERY_KEYS = {
  SEARCH: "search",
};

const SearchWithAutoComplete = ({ onSelect, standAlone, onChange, types, placeholder, initValue }) => {
  const [queryString, setQueryString] = useState("");
  const [value, setValue] = useState({});
  const [options, setOptions] = useState([]);

  // do not query while true
  const [isTyping, setIsTyping] = useState(false);
  //golds the debounce isTyping timer
  const timer = useRef(null);

  const { refetch } = useQuery(
    QUERY_KEYS.SEARCH,
    async () => {
      if (queryString.length < 3) return [];
      const response = await request({
        url: `new-ships-api/public/search?itemTypes=${types.join("&itemTypes=")}&term=${queryString}`,
        method: "GET",
      });

      setOptions(
        response.map(({ id, name, detail2, type }) => {
          let url = ``
          let logo = '/app/assets/images/logo.png'
          switch (type) {
            case "shipyard":
              url = `/shipyards/${id}-${_.kebabCase(name)}`
              break;
            case "ship_manager":
              url = `/orderers/${id}-${_.kebabCase(name)}`
              logo = '/app/assets/images/logo_orderer.png'
              break;
            case "company_group":
              url = `/company-group/${id}-${_.kebabCase(name)}`
              logo = '/app/assets/images/shipyard_agent.png'
              break;
            case "order":
              name = name || `New Order (${id})`
              url = `/orders/${id}-${_.kebabCase(name)}`
              logo = '/app/assets/images/logo_order.png'
              break;
            default:
              url = `/company/${id}-${_.kebabCase(name)}`
              break;
          }
          return {
            value: url,
            name,
            type,
            label: (
              <div>
                <Row>
                  <Col
                    span={4}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={logo}
                      alt="shipyard"
                      style={{ minWidth: "25px" }}
                    />
                  </Col>
                  <Col span={20}>
                    <Row>
                      <Col span={24}>
                        <span style={{ fontWeight: "500" }}>{name}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>{detail2}</Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ),
          };
        })
      );
      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (queryString.length < 3 || isTyping) return [];
    refetch();
  }, [isTyping]); //eslint-disable-line

  const handleSearch = (value) => {
    setQueryString(value);

    // user starts typing
    timer.current = null;
    // user continued typing so cancel the stop typing timer
    setIsTyping(true);
    timer.current = setTimeout(() => {
      // user stops typing
      setIsTyping(false);
    }, 300);
  };

  const defaultOnSelect = (value) => {
    setValue({ name: "" })
    setTimeout(() => {
      const url = window.location.origin + "/app" + value;
      window.open(url, "_blank");
    }, 500);
  };

  const handleSelect = (val) => {
    const selectedOption = options.find((option) => option.value === val);
    if (standAlone) {
      defaultOnSelect(val)
    } else {
      setValue(selectedOption)
      onSelect(selectedOption)
    }
  }

  const handleClick = (e) => {
    if (e?.target?.nodeName?.toLowerCase() !== 'input' && options.length === 0 && queryString.length > 0) {
      message.warning('There are no results for your search. Please try minimizing your keywords."');
    }
  }

  const handleSelectDirect = (selectedOption) => {
    setValue(selectedOption)
    // onSelect(selectedOption)
  }

  useEffect(() => {
    if (initValue?.name) {
      setValue(initValue);
    }
  }, [initValue?.name]);

  return (
    <AutoComplete
      style={{ ...(standAlone && { marginTop: "1.2rem", width: 400 }) }}
      onSelect={handleSelect}
      onChange={(val) => {
        if (val === "") {
          setValue({});
          if (onChange) onChange("");
        } else {
          setValue({ name: val });
        }
      }}
      value={value?.name}
      onFocus={() => {
        setOptions([])
        // if (standAlone) setValue("")
        // else onChange("")
      }}
      onSearch={handleSearch}
      options={options}
      onClick={handleClick} 
    >
      <Input.Search size="medium" placeholder={placeholder} />
    </AutoComplete>
  );
};

export default SearchWithAutoComplete;
