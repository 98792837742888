import React, { useEffect, lazy } from "react";
import { useDispatch } from "react-redux";
import Wrapper from "./Wrapper";
import { updateWindowWidth } from "../../appRedux/actions";
import { Redirect, Route, Switch } from "react-router-dom";

import Search from "../Search";
import SignIn from "../SignIn";
import EmailConfirmation from "../EmailConfirmation";
import PaymentSuccess from "../PaymentSuccess";

const Home = lazy(() => import('../../routes/Home'));
const News = lazy(() => import("../News"));
const Order = lazy(() => import("../Order"));
const Vessel = lazy(() => import("../Vessel"));
const RecyclingGuide = lazy(() => import("../RecyclingGuide"));
const ConstructionGuide = lazy(() => import("../ConstructionGuide"));
const NewBuildTracker = lazy(() => import("../NewBuildTracker"));
const RecyclingTracker = lazy(() => import("../RecyclingTracker"));
const Orderer = lazy(() => import("../Orderer"));
const NewsDetails = lazy(() => import("../../components/NewsDetails"));
const Statistics = lazy(() => import("../Statistics"));
const RegisterSuccess = lazy(() => import("../RegisterSuccess"));
const RegisterRejected = lazy(() => import("../RegisterRejected"));

const LoginOrRegister = lazy(() => import("../LoginOrRegister"));
const Shipyard = lazy(() => import("../Shipyard"));
const CompanyGroup = lazy(() => import("../CompanyGroup"));
const Shipmanager = lazy(() => import("../Shipmanager"));
const Profile = lazy(() => import("../Profile"));

const MainApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const start = "/";
  return (
    <Wrapper>
      <div className="gx-main-content-wrapper">
        <Switch>
          <Route
            path={`${start}email-confirmation`}
            component={EmailConfirmation}
          />
          {/* <Route path={`${start}dashboard`} component={Dashboard} /> */}
          <Route path={`${start}profile`} component={Profile} />
          <Route path={`${start}shipyards/:slug`} component={Shipyard} />
          <Route path={`${start}ship-manager/:slug`} component={Shipmanager} />
          <Route
            path={`${start}company-group/:slug`}
            component={CompanyGroup}
          />
          <Route path={`${start}vessels/:slug`} exact component={Vessel} />
          <Route
            path={`${start}login-or-register`}
            component={LoginOrRegister}
          />
          <Route
            path={`${start}register-success`}
            component={RegisterSuccess}
          />
          <Route
            path={`${start}register-rejected`}
            component={RegisterRejected}
          />
          <Route path={`${start}orders/:slug`} component={Order} />
          <Route path={`${start}orderers/:slug`} component={Orderer} />
          <Route path={`${start}signIn`} component={SignIn} />
          <Route path={`${start}auth`} component={SignIn} />
          <Route path={`${start}home`} component={Home} />
          <Route path={`${start}search`} component={Search} />
          <Route path={`${start}statistics`} component={Statistics} />
          <Route path={`${start}news/:slug`} component={NewsDetails} />
          <Route path={`${start}news`} component={News} />
          <Route path={`${start}guide/recycling`} component={RecyclingGuide} />
          <Route
            path={`${start}guide/construction`}
            component={ConstructionGuide}
          />
          <Route
            path={`${start}tracker/new-build`}
            component={NewBuildTracker}
          />
          <Route
            path={`${start}tracker/recycling`}
            component={RecyclingTracker}
          />
          <Route exact path={`${start}`}>
            <Redirect to={`${start}news`} />
          </Route>
          <Route path={`${start}app`}>
            <Redirect to={`${start}news`} />
          </Route>
          <Route exact path={`/payment-success`} component={PaymentSuccess} />
          <Route path="*">
            <div>404 page not found</div>
          </Route>
        </Switch>
      </div>
    </Wrapper>
  );
};
export default MainApp;
