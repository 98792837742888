import { useCallback } from "react";
import { useQuery } from "react-query";
import request from "../../util/request";
import _ from "lodash";
import moment from "moment";

const QUERY_KEYS = {
  ORDERER_MAIN: "orderer-main",
  ORDERER_VESSELS: "orderer-vessels",
  ORDERER_GEODATA: "orderer-geodata",
  ORDERER_PERMISSIONS: "orderer-permissions",
  ORDERER_ORDERS: "orderer-orders",
  ORDERBOOK: "on-orders",
  ORDERBOOK_LATEST: "on-orders-latest",
  BUILDER: "builder",
  DELIVERED: "deliverd",
  DELIVERS: "delivers",
  SHIPYARD_MAIN: "shipyard-main",
  RECYCLING_SHIPYARDS: "recycling-shipards",
  RECYCLING_VESSELS: "recycling-vessels",
  RECYCLING_DATA: "recycling-data",
};

const usePageQueries = ({
  currentVesselType,
  typeId,
  isAuthenticated,
  tableVariables,
  currentTab,
  buildersTableVariables,
  orderersTableVariables,
  deliversTableVariables,
  deliveredTableVariables,
}) => {

  const {
    data: recyclingData,
    isLoading: recyclingDataLoading,
    isError: recyclingDataError,
  } = useQuery(
    QUERY_KEYS.RECYCLING_DATA,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/events/recycling`,
        method: "GET",
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: recyclingShipyards,
    isLoading: recyclingShipyardsLoading,
    isError: recyclingShipyardsError,
  } = useQuery(
    QUERY_KEYS.RECYCLING_SHIPYARDS,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/events/recycling/shipyards`,
        method: "GET",
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: recyclingVessels,
    isLoading: recyclingVesselsLoading,
    isError: recyclingVesselsError,
  } = useQuery(
    QUERY_KEYS.RECYCLING_VESSELS,
    async () => {
      const response = await request({
        url: `new-ships-api/${isAuthenticated ? "user" : "public"
          }/events/recycling/vessels`,
        method: "GET",
      });
      return response;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const {
    data: permissions,
    isLoading: permissionsLoading,
    isError: permissionsError,
  } = useQuery(
    QUERY_KEYS.ORDERER_PERMISSIONS,
    async () => {
      const response = await request({
        url: `new-ships-api/${
          isAuthenticated ? "user" : "public"
        }/content-permissions`,
        method: "GET",
        params: {
          page: "nso_order",
        },
      });

      return response;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 30000000,
      refetchOnWindowFocus: false,
      refetch: false,
    }
  );

  const {
    data: main,
    isLoading: pageDataLoading,
    isError: mainError,
  } = useQuery(
    QUERY_KEYS.SHIPYARD_MAIN,
    async () => {
    const url = `new-ships-api/${false ? "user" : "public"}/news${
      typeId !== undefined ? `?typeId=${typeId}` : ""
    }`;

    const response = await request({
      url,
      method: "GET",
    });

    return response;
  },

    { refetchOnMount: false, refetchOnWindowFocus: false }
  );

  const fetchOrders = useCallback(async () => {
    const params = {
      currentPage: tableVariables.pagination.current - 1,
      pageSize: tableVariables.pagination.pageSize,
      orderBy: tableVariables.sort.field,
      order: tableVariables.sort.order || "ascend",
      tdStatus: "ON_ORDER",
      vesselTypeStr: currentVesselType,
      orderDateStart: undefined,
    };

    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/order/search`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });
    return response;
  }, [tableVariables, isAuthenticated, currentVesselType]);

  const fetchLatestOrders = useCallback(async () => {
    let ninetyDaysAgo = moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD');

    const params = {
      currentPage: tableVariables.pagination.current - 1,
      pageSize: tableVariables.pagination.pageSize,
      orderBy: tableVariables.sort.field,
      order: tableVariables.sort.order || "ascend",
      tdStatus: "ON_ORDER",
      vesselTypeStr: currentVesselType,
      orderDateStart: ninetyDaysAgo,
    };

    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/order/search`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });
    return response;
  }, [tableVariables, isAuthenticated, currentVesselType]);

  const { data, refetch } = useQuery(
    [QUERY_KEYS.ORDERBOOK, tableVariables, currentVesselType],
    fetchOrders,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  const { data: latestOrders, refetch: refetchLatestOrders } = useQuery(
    [QUERY_KEYS.ORDERBOOK_LATEST, tableVariables, currentVesselType],
    fetchLatestOrders,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  const fetchDelivers = useCallback(async () => {
    const params = {
      currentPage: deliversTableVariables.pagination.current - 1,
      pageSize: deliversTableVariables.pagination.pageSize,
      orderBy: deliversTableVariables.sort.field,
      order: deliversTableVariables.sort.order || "ascend",
      tdStatus: "DELIVER_EXPECTED",
      vesselTypeStr: currentVesselType,
    };

    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/order/search`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });
    return response;
  }, [deliversTableVariables, isAuthenticated, currentVesselType]);

  const { data: delivers } = useQuery(
    [QUERY_KEYS.DELIVERS, deliversTableVariables, currentVesselType],
    fetchDelivers,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  const fetchDelivered = useCallback(async () => {
    let thirtyDaysAgo = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

    const params = {
      currentPage: deliveredTableVariables.pagination.current - 1,
      pageSize: deliveredTableVariables.pagination.pageSize,
      orderBy: deliveredTableVariables.sort.field,
      order: deliveredTableVariables.sort.order || "ascend",
      deliveryDateStart: thirtyDaysAgo,
      tdStatus: "DELIVERED",
      vesselTypeStr: currentVesselType,
    };

    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/order/search`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });
    return response;
  }, [deliveredTableVariables, isAuthenticated, currentVesselType]);

  const { data: delivered } = useQuery(
    [QUERY_KEYS.DELIVERED, deliveredTableVariables, currentVesselType],
    fetchDelivered,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );


  const fetchBuilders = useCallback(async () => {
    const params = {
      currentPage: buildersTableVariables?.pagination?.current - 1,
      pageSize: buildersTableVariables.pagination.pageSize,
      orderBy: buildersTableVariables.sort.field,
      order: buildersTableVariables.sort.order || "ascend",
      vesselTypeStr: currentVesselType,
    };

    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/shipyards`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });
    return response;
  }, [buildersTableVariables, isAuthenticated, currentVesselType]);

  const { data: builders } = useQuery(
    [QUERY_KEYS.BUILDER, buildersTableVariables, currentVesselType],
    fetchBuilders,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  const fetchOderers = useCallback(async () => {
    const params = {
      currentPage: orderersTableVariables.pagination.current - 1,
      pageSize: orderersTableVariables.pagination.pageSize,
      orderBy: orderersTableVariables.sort.field,
      order: orderersTableVariables.sort.order || "ascend",
      vesselTypeStr: currentVesselType,
    };

    const response = await request({
      url: `new-ships-api/${isAuthenticated ? "user" : "public"}/orderer`,
      method: "GET",
      params: _.omitBy(params, _.isNil),
    });
    return response;
  }, [orderersTableVariables, isAuthenticated, currentVesselType]);

  const { data: orderers } = useQuery(
    [QUERY_KEYS.ORDERERS, orderersTableVariables, currentVesselType],
    fetchOderers,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  const fetchNews = useCallback(async () => {
    const params = typeId !== undefined ? { type_id: typeId } : {};
    const response = await request(
      {
        url: `api/v1/news/`,
        method: "GET",
        params,
      },
      true
    );
    return response;
  }, [typeId, orderersTableVariables, isAuthenticated, currentVesselType]);
  

  const { data: news } = useQuery(
    ["news_table", typeId],
    fetchNews,
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 300000,
      refetchOnWindowFocus: false,
    }
  );


  return {
    delivered,
    delivers,
    builders,
    orderers,
    news,
    permissions,
    permissionsLoading,
    permissionsError,
    data,
    refetch,
    main,
    pageDataLoading,
    mainError,
    latestOrders,
    refetchLatestOrders,
    recyclingShipyards,
    recyclingVessels,
    recyclingData
  };
};

export default usePageQueries;
