import React from "react";
import Widget from "components/Widget/index";

const NeedAddonCard = () => {
  return (
    <Widget styleName="gx-bg-orange">
      <div className="gx-flex-row gx-justify-content-center gx-mb-3 gx-mb-md-4 gx-p-3">
        <i className={`icon icon-refer gx-fs-xlxl gx-text-white`} />
      </div>
      <div className="gx-text-center">
        <h2 className="h3 gx-mb-3 gx-text-white">Premium account needed for this content</h2>
      <p className="gx-text-white gx-mb-3">The following content is available only for premium users, please  <a target="_blank" href="https://new-ships-discover.trusteddocks.com/contact-us/">contact us</a> to gain access.</p>
      </div>
    </Widget>
  );
};

export default NeedAddonCard;
