import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import * as serviceWorker from './registerServiceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

ReactDOM.render(<NextApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const checkForUpdate = async () => {
    try {
      console.log('Fetching appversion...')
      const response = await fetch('/app/meta.json', { cache: "no-store" });
      const { version } = await response.json();
  
      const currentVersion = localStorage.getItem('appVersion');
  
      if (currentVersion && currentVersion !== version) {
        console.log("New version available! Reloading...");
        localStorage.setItem('appVersion', version);
        window.location.reload();
      } else {
        localStorage.setItem('appVersion', version);
      }
    } catch (error) {
      console.error("Failed to check for updates:", error);
    }
  };

setInterval(checkForUpdate, 60000);
