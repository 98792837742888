import _ from "lodash";

import Widget from "components/Widget";
import { Button } from "antd";


const NoCompanyFoundCard = ({ shipyard, title, clickEvent }) => {
  return (
    <Widget styleName="gx-card-profile">
      <div className="ant-card-head">
        <span className="ant-card-head-title gx-mb-0"></span>
      </div>
      <div className="gx-pb-md-3">
        <div className="">
          
          <div className="gx-media-body gx-featured-content">
            <div className="gx-featured-content-left">
              <h3 className="gx-text-primary gx-mt-sm-auto gx-pointer gx-mb-4">{shipyard.name}</h3>

              <div className="">
                <Button type="primary" onClick={clickEvent}>
                  Add new company
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Widget>
  )
};

export default NoCompanyFoundCard;