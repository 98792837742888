import React from "react";
import { Avatar, Popover } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const UserInfo = () => {
  const {
    user,
    logout,
  } = useAuth0();


  const logoutWithRedirect = () => {
    logout({
      returnTo: window.location.origin + "/app",
    });

    localStorage.removeItem("apiToken");
    localStorage.removeItem("redirect");
    return
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        <Link to="/profile">
          User Profile
        </Link>
      </li>
      <li onClick={(e) => {
        e.preventDefault();
        logoutWithRedirect();
      }}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="" placement="bottomLeft" content={userMenuOptions} trigger="hover">
      <Avatar src={user.picture} className="gx-avatar gx-pointer" alt="" />
    </Popover>
  )

}

export default UserInfo;
